import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {EditAllHouseholdInfoQueryString, Message, SubscribeContextQueryString} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';
import {withSearchParams} from '../../common/util';

export const SubscribeFindHousehold = () => {
  const onFindHousehold = useCallback(() => {
    navigateTo(withSearchParams(OnboardingPages.HouseholdFind, SubscribeContextQueryString));
  }, []);
  const onNewHousehold = useCallback(() => {
    navigateTo(withSearchParams(
      OnboardingPages.HouseholdLastName,
      SubscribeContextQueryString,
      EditAllHouseholdInfoQueryString
    ));
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={18}>Have you already created a household?</Message>
      )}
      footerContent={(
        <>
          <Button onClick={onFindHousehold}>I ALREADY HAVE A HOUSEHOLD</Button>
          <Button onClick={onNewHousehold}>CREATE NEW HOUSEHOLD</Button>
        </>
      )}
      onEnterKeypress={onFindHousehold}
    />
  );
};
