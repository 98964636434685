import React, {useCallback, useEffect, useMemo, useState} from 'react';
import '../shared.css';
import {hymnalsPath} from '../shared';
import {Header} from './header';
import './player_page.css';
import './footer.css';
import {ReactComponent as PlayButton} from '../assets/play-circle.svg';
import {ReactComponent as PauseButton} from '../assets/pause-circle.svg';
// REPEAT_BUTTON
// import {ReactComponent as Repeat} from '../assets/repeat-rounded.svg';
import {ReactComponent as SkipForwardIcon} from '../assets/play-skip-forward.svg';
import {ReactComponent as SkipBackwardIcon} from '../assets/play-skip-back.svg';
import {ReactComponent as OutlinedHeartIcon} from '../assets/heart-outline.svg';
import {ReactComponent as HeartIcon} from '../assets/heart.svg';
import {ReactComponent as ArrowRight} from '../assets/arrow-forward.svg';
import {ReactComponent as ArrowLeft} from '../assets/arrow-back.svg';
import {RequestSongPage} from './request_song_page';
import {Slider} from '../util/slider';
import {Hymn, Sequencer} from '../sequencer';
import {generateFileName, generatePathFor} from '../util/path';
import {userVisibleSongNumber} from "../common/util";
import {ModalNotification} from '../modal';
import {favorites} from '../data/favorites';
import {isDesktop} from 'react-device-detect';
import {SlideMenu} from '../slide_menus';
import {cacheGet, CacheKey, defaultVerseCount, Voice, VoiceValues} from '../data/client_cache';
import {useCache} from '../data/use_cache';
import {useSwipeable} from 'react-swipeable';
import {VerseBar} from '../verse_bar';
import {HymnIssue} from "../common/model";
import {isWKWebView} from "../authentication/apple_login";
import {ensureExists} from "../common/util";
import styled from 'styled-components/macro';
import {ReactComponent as VocalsIcon} from "../assets/vocals.svg";
import {ReactComponent as DocumentTextOutline} from "../assets/document-text-outline.svg";
import {songViews} from '../data/song_views';
import {Stopwatch} from '../util/stopwatch';
import {PdfViewerPage} from './pdf_viewer_page';
import {useUserAttributes} from '../data/use_user_attributes';

interface Props {
  hymns: Hymn[];  //optional list of songs to be played
  onBack?: () => void;  //optional callback function for back button in header
}

const introText = 'Int';

enum Display {PDF, Info, SongSettings}

export const PlayerPage = ({hymns, onBack}: Props) => {
  const [display, setDisplay] = useState(Display.SongSettings);
  const [hymn, setHymn] = useState<Hymn>(hymns[0]);
  const [modal, setModal] = useState('');
  const [slideMenu, setSlideMenu] = useState(false);
  const [txtFileParsed, setTxtFileParsed] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [defaultTempo, setDefaultTempo] = useState('-');
  const [sliderTimestamp, setSliderTimestamp] = useState(0);
  const [verse, setVerse] = useState('1');
  const [selectedVoice, setSelectedVoice] = useState('')
  const [isFavorite, setIsFavorite] = useState(() =>
    favorites.includes(hymn)
  );
  const [vocalsEnabled, setVocalsEnabled] = useState(false);
  const [stopwatch] = useState(() => new Stopwatch());
  const [user] = useCache(CacheKey.User);
  const {isInternalUser} = useUserAttributes();

  // todo(hewitt): preload the browser forward history with the PDF page to swipe from right to left
  //   the challenge is that useEffect is called in response to navigateTo, even with the 'ignore' option
  // const {location, navigateTo} = useLocation();
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search)
  //   searchParams.set('showPDF', 'true');
  //   const pdfURL = location.pathname + '?' + searchParams.toString();
  //   navigateTo(pdfURL, LocationNavigationOption.Ignore);
  //   window.history.back();
  // })

  const addFavorite = useCallback(() => {
    favorites.add(hymn);
    setIsFavorite(true)
  }, [hymn]);

  const removeFavorite = useCallback(() => {
    favorites.remove(hymn)
    setIsFavorite(false)
  }, [hymn]);


  const renderFavoriteButton = useCallback(() => {
    if (isFavorite) {
      return <HeartIcon className="heartIcon" onClick={() => removeFavorite()}/>
    } else {
      return (
        <OutlinedHeartIcon className="heartIcon" onClick={user === "guest" ? () => setModal('login-favorites') : () => addFavorite()}>
          Add Favorite
        </OutlinedHeartIcon>
      );
    }
  }, [addFavorite, isFavorite, removeFavorite, user]);


  const txtFile = generateFileName(hymn) + '.txt';
  const title = (hymn.number ? userVisibleSongNumber(hymn.number) + ' - ' : '') + ensureExists(hymn.title);

  const isLoaded = txtFileParsed && readyToPlay;

  // cache for performance during playback
  const [voiceValues, setVoiceValues] = useCache(CacheKey.VoiceValues, hymn.vocalPartCount === "2" ? {
    [Voice.Soprano]: 1,
    [Voice.Alto]: 0.5,
    [Voice.Tenor]: 1, // Turn the Tenor (Bass) all the way up by default for 2 vocal parts
    [Voice.Bass]: 0.5,
    [Voice.Piano]: 0.5,
  } : undefined);

  useEffect(() => {
    (async () => {
      const response = await fetch(generatePathFor(hymnalsPath, ensureExists(hymn.hymnal), txtFile));
      const content = await response.text();
      parseTextFile(content);

      function parseTextFile(content: string) {
        const lines = content.split('\n');
        const parseValue = (key: string) =>
          lines.filter(line => line.startsWith(key)).map(line => line.match(/^[a-z_]+\s+(.*)$/)?.[1])[0];
        const maybeVerseCount = parseValue('verse_count');
        hymn.verseCount = maybeVerseCount ? Number(maybeVerseCount) : undefined;
        hymn.author = parseValue('author');
        hymn.composer = parseValue('composer');
        hymn.harmony = parseValue('harmony');
        hymn.copyright = parseValue('copyright');
        hymn.key= parseValue('key');
        hymn.vocals = parseValue('vocals');
        hymn.vocalPartCount = parseValue('vocal_part_count');
        setTxtFileParsed(true);
      }
    })();
  }, [hymn, txtFile]);

  // Record a song view whenever the hymn changes or this page closes
  useEffect(() => {
    return () => {
      if (stopwatch.totalTimeMs === 0) {
        return;
      }
      songViews.add({
        songNumber: hymn.number,
        hymnalName: ensureExists(hymn.hymnal),
        durationMs: stopwatch.totalTimeMs,
      });
      stopwatch.clear();
      void songViews.uploadToServer();
    };
  }, [stopwatch, hymn]);

  // Count time viewing PDF toward song view
  useEffect(() => {
    const pdfStopwatchSubscription = display === Display.PDF ? stopwatch.subscribe() : undefined;
    return () => {
      if (pdfStopwatchSubscription) {
        stopwatch.unsubscribe(pdfStopwatchSubscription);
      }
    }
  }, [display, stopwatch]);

  let verseCount = cacheGet(CacheKey.VerseCount)
    || hymn.verseCount
    || defaultVerseCount;


  const hidePlayer = (hymn.issue === HymnIssue.Music && !isInternalUser) || hymn.issue === HymnIssue.Missing;

  const sequencer = useMemo(() => {
    function onHymnChange(hymn: Hymn) {
      setHymn(hymn);
    }
    function onReadyToPlay(tempo: number) {
      setDefaultTempo(tempo.toString());
      setReadyToPlay(true);
    }

    function onNetworkError() {
      setIsNetworkError(true);
    }

    function onVerseChange(verse: string) {
      if(verse === '0' && cacheGet(CacheKey.SongIntro)) {
        setVerse(introText)
      } else if(verse === '0' || !cacheGet(CacheKey.SongIntro)) {
        setVerse((Number(verse) + 1).toString())
      } else {
        setVerse(verse)
      }
    }

    return new Sequencer(
      hymns, {
        onHymnChange,
        setPlaying,
        onReadyToPlay,
        onNetworkError,
        onVerseChange: onVerseChange,
        stopwatch,
      });
  }, [hymns, stopwatch]);

  const setVoiceValue = useCallback((voice: Voice, value: number): void => {
    setVoiceValues(fixVoiceValues({...voiceValues, [voice]: value}));
    sequencer.onTempoOrVolumeChange();
  }, [voiceValues, setVoiceValues, sequencer]);


  const songIntroCachedValue = cacheGet(CacheKey.SongIntro);

  useEffect(() => {
    sequencer.reset();
  }, [songIntroCachedValue, sequencer])

  useEffect(() => {
    return () => sequencer.destroy()
  }, [sequencer])

  // REPEAT_BUTTON
  // const [repeat, setRepeat] = useCache(CacheKey.Repeat);
  //
  // const updateRepeat = useCallback((value: boolean) => {
  //   setRepeat(value);
  // }, [setRepeat]);

  function handleVoiceButtonPress(voice: Voice) {
    if (voice === selectedVoice) {
      setSelectedVoice('');
      setVoiceValues({...voiceValues,
        [Voice.Soprano]: 1,
        [Voice.Alto]: 0.5,
        [Voice.Tenor]: 0.5,
        [Voice.Bass]: 0.5,
        [Voice.Piano]: 0.5,
      });
    } else {
      setSelectedVoice(voice);
      setVoiceValues({
        ...voiceValues,
        [Voice.Soprano]: 0,
        [Voice.Alto]: 0,
        [Voice.Tenor]: 0,
        [Voice.Bass]: 0,
        [Voice.Piano]: 0,
        [voice]: 1
      });
    }
    sequencer.onTempoOrVolumeChange();
  }

  function handleVoiceSliderChange(voice: Voice, value: number) {
    setSelectedVoice('');
    setVoiceValue(voice, value);
  }

  function renderUpperDiv() {
    return <div className='upperDiv'>
      <form id='slider-form'>
        <table className='sliderTable'>
          <colgroup>
            <col className='sliderLabelColumn'/>
          </colgroup>
          <tbody>
          {
            Object.values(Voice).map(voice => {
              if (voice === Voice.Piano && !vocalsEnabled) {
                return null;
              }
              // only show soprano & tenor if two parts
              if (voice !== Voice.Soprano && voice !== Voice.Tenor && hymn.vocalPartCount === "2") {
                return null;
              }
              function getVoiceName(): string {
                let voiceName: string;
                if (hymn.vocalPartCount !== "2") {
                  voiceName = voice;
                } else if (voice === Voice.Soprano) {
                  voiceName = 'Treble';
                } else if (voice === Voice.Tenor) {
                  voiceName = 'Bass';
                } else {
                  voiceName = voice;
                }
                return voiceName.charAt(0).toUpperCase() + voiceName.slice(1);
              }
              return (
                <tr key={voice}>
                  <td className={voice === Voice.Piano ? 'pianoVoiceSpacer' : ''}>
                    <div
                      className={`voiceButton ${
                        voice === selectedVoice ? 'selectedVoice' : ''
                      }`}
                      onClick={() => handleVoiceButtonPress(voice)}>
                      <label className='sliderLabel' id={`${getVoiceName()}-label`}>
                        {getVoiceName()}
                      </label>
                    </div>
                  </td>
                  <td className={`sliderCell ${voice === Voice.Piano ? 'pianoVoiceSpacer' : ''}`}>
                    <Slider
                      id={voice}
                      className={`letter_${getVoiceName().charAt(0).toLowerCase()}`}
                      defaultValue={voiceValues[voice]}
                      value={voiceValues[voice]}
                      min={0}
                      max={1}
                      step={0.01}
                      onPointerUp={(value) => {
                        setSliderTimestamp(Date.now());
                        handleVoiceSliderChange(voice, value);
                      }}
                    />
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </form>
    </div>;
  }

  const renderTempoSlider = useCallback(() => {
    return (
      <tbody>
        {vocalsEnabled ?
          null : // piano slider
          <tr>
            <td className='tempoCell'>
              <label className='sliderLabel' id='tempo-label'>Tempo</label><br/>
              <div className='bpm sliderLabel' id='tempo-output'>{defaultTempo}</div>
            </td>
            <td className='sliderCell'>
              <input className='tempo' type='range' id='tempo-slider' defaultValue={1} min={0.5} max={1.5}
                     step={0.01}
                     onPointerUp={() => {
                       setSliderTimestamp(Date.now());
                       sequencer.onTempoOrVolumeChange();
                     }}
              />
            </td>
          </tr>
        }
      </tbody>
    )
  }, [
    defaultTempo,
    sequencer,
    vocalsEnabled
  ]);

  const renderTimeSlider = useCallback(() => {
    let pointerDown = false;
    return (
      <tbody>
      <tr>
        <td className='timeCell'><label className='sliderLabel' id='time-output'>{sequencer.initialTime}</label></td>
        <td className='sliderCell'><input className='timeSliderThumb' type='range' id='time-slider'
                                          onInput={function () {
                                            sequencer.displayTime();
                                            // Normally we don't set the slider time until the pointer up event.
                                            // However, iOS sends the onInput *after* onPointerUp when the user taps
                                            // the slider instead of drag/drop (bad Apple).
                                            // So, if the pointer is already up, we let onInput set the slider time.
                                            if (!pointerDown) {
                                              sequencer.setNewTime();
                                              setSliderTimestamp(Date.now());
                                            }
                                          }}
                                          onPointerUp={function () {
                                            sequencer.setNewTime();
                                            setSliderTimestamp(Date.now());
                                            pointerDown = false;
                                          }}
                                          onPointerDown={() => {pointerDown = true}}
                                          onChange={() => console.log(`onChange`)}
                                          defaultValue={0} min={0} max={100} step={0.01}/></td>
        </tr>
      </tbody>
    )
  }, [sequencer]);

  const handlePlay = useCallback(() => {
    if (cacheGet(CacheKey.PdfAutoDisplay)) {
      setDisplay(Display.PDF)
      setTimeout(() => sequencer.play(), 300)
    } else {
      sequencer.play();
    }
  }, [sequencer]);

  useEffect(() => {
    // Register for the iPhone lock screen
    // See this tutorial: https://web.dev/media-session/
    if ("mediaSession" in navigator && hymn && playing) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: hymn.title,
        artist: hymn.author ?? hymn.composer ?? '',
        album: hymn.hymnal,
        artwork: [
          {src: `${hymnalsPath}/${hymn.hymnal}/icon.png`, sizes: '128x128', type: 'image/png'},
        ]
      });
      navigator.mediaSession.setActionHandler('play', () => sequencer.play());
      navigator.mediaSession.setActionHandler('pause', () => sequencer.pause());
      if (hymns.length > 1) {
        navigator.mediaSession.setActionHandler('previoustrack', () => sequencer.previousSong());
        navigator.mediaSession.setActionHandler('nexttrack', () => sequencer.nextSong());
      }
    }
  }, [hymn, hymns.length, sequencer, playing]);

  const handleVerseClick = useCallback((verseIndex: string, introValue: boolean | undefined) => {
    sequencer.changeVerse(verseIndex, introValue);
  }, [sequencer]);

  const toggleVocalsEnabled = useCallback(() => {
    const newValue = !vocalsEnabled;
    setReadyToPlay(false);
    sequencer.setVocalsEnabled(newValue);
    setVocalsEnabled(newValue);
  }, [vocalsEnabled, sequencer]);

  function handleSwipe() {
    let diff = Date.now() - sliderTimestamp;
    if (diff > 50) {
      setDisplay(Display.PDF)
    }
  }

  const swipeLeftHandler = useSwipeable({
    onSwipedLeft: () => handleSwipe(),
  });

  const renderPlayPauseButton = useCallback(() => {

    // if not playing, display play button
    if (!playing) {
      return (
        <button
          id='play-button'
          className='playPauseButton'
          onClickCapture={() => handlePlay()}>
          <PlayButton className='playPauseIcon'/>
        </button>
      )
    }
    // if playing, display pause button
    return (
      <button
        id='pause-button'
        className='playPauseButton'
        onClick={sequencer.pause}>
        <PauseButton className='playPauseIcon'/>
      </button>
    )
  }, [
    handlePlay,
    playing,
    sequencer.pause,
  ]);

  const renderPlaybackButtons = useCallback(() => {

    // if network error, display error
    if (isNetworkError) {
      return (
        <label className='loading' id='error'>Error</label>
      )
    }
    // if not loaded, display loading
    if (!isLoaded) {
      return (
        <label className='loading' id='loading'>Loading</label>
      )
    }
    // if playing more than one song, return playback buttons
    if (hymns!.length > 1) {
      return (
        <>
          <div style={{position: 'relative', width: '60px'}}>
            <button onClick={() => sequencer.previousSong()} className="skipIconLeft">
              <SkipBackwardIcon/>
            </button>
          </div>
          <div>
            {renderPlayPauseButton()}
          </div>
          <div style={{position: 'relative', width: '60px'}}>
            <button onClick={() => sequencer.nextSong()} className="skipIconRight">
              <SkipForwardIcon/>
            </button>
          </div>
        </>
      )
    }
    // return play/pause button by default
    return (
      <div>
        {renderPlayPauseButton()}
      </div>
    )

  }, [
    hymns,
    isLoaded,
    isNetworkError,
    renderPlayPauseButton,
    sequencer,
  ]);

  const renderVocalsButton = useCallback(() => {

    return (
      <VocalsButtonBlock $visible={Boolean(hymn.vocals)}>
        <div className={`voiceButton`} onClick={toggleVocalsEnabled}>
          <VocalsButtonContentWrapper $enabled={vocalsEnabled}>
            <VocalsIcon className="vocalsIcon"/>
            <label className='sliderLabel'>
              Vocals
            </label>
          </VocalsButtonContentWrapper>
        </div>
      </VocalsButtonBlock>
    )
  }, [
    hymn.vocals,
    toggleVocalsEnabled,
    vocalsEnabled,
  ]);

  const renderVerses = useCallback(() => {

    return (
      <div hidden={
        vocalsEnabled ||
        // TODO(hewitt): should emply useCache for both of these variables
        //               in order to have automatic invalidation
        //               generally React code should not call `cacheGet` directly
        (cacheGet(CacheKey.VerseCount) === 1 && !cacheGet(CacheKey.SongIntro))
      } id='lowerPlayerIcons'>
        <div style={{display: 'inline-block'}}>
          <VerseBar
            intro={songIntroCachedValue}
            verseCount={verseCount}
            verse={verse}
            handleClick={handleVerseClick}
          />
        </div>
      </div>
    )
  }, [
    handleVerseClick,
    songIntroCachedValue,
    verse,
    verseCount,
    vocalsEnabled,
  ]);

  const renderButtons = useCallback(() => {

    return (
      <div className='bottomDiv' id='bottom-div' {...swipeLeftHandler}>
        <div className="icons" style={{position: "relative"}}>
          <div id="playerIcons" style={{position: "relative"}}>
            <div id='upperPlayerIcons'>

              <div id='heartContainer'>
                <div>
                  {renderFavoriteButton()}
                </div>
              </div>

              <div id='playbackIconsContainer'>
                {renderPlaybackButtons()}
              </div>

              <div id='repeatContainer'>
                <DocumentTextOutline className="repeatButton" onClick={() => setDisplay(Display.PDF)}/>
                {/* REPEAT_BUTTON */}
                {/*<div hidden={!repeat}>*/}
                {/*  <Repeat className="repeatButton" onClick={() => updateRepeat(false)}/>*/}
                {/*</div>*/}
                {/*<div hidden={repeat}>*/}
                {/*  <Repeat className="repeatButton greyedOutSvg" onClick={() => updateRepeat(true)}/>*/}
                {/*</div>*/}
              </div>
            </div>

            {renderVocalsButton()}
            {renderVerses()}

          </div>
        </div>
      </div>
    );
  }, [
    renderFavoriteButton,
    renderPlaybackButtons,
    renderVerses,
    renderVocalsButton,
    swipeLeftHandler,
  ]);

  function renderLowerDiv() {
    return (
      <div className='lowerDiv'>
        <form id='second-slider-form'>
          <table className='tempoTimeSliderTable'>
            {renderTempoSlider()}
            {renderTimeSlider()}
          </table>
        </form>
        {renderButtons()}
      </div>
    );
  }

  function renderPlayerUI() {
    return (
      <div className='playerPageContent'>
        {renderUpperDiv()}
        {renderLowerDiv()}
      </div>
    )
  }

  function renderControls() {
    return (
      <div
        key='player'
      >
        <div style={{...(!hidePlayer && {display: 'none'})}}>
          {
            hymn.issue && (!isInternalUser || hymn.issue === HymnIssue.Missing)
              ? <RequestSongPage issue={hymn.issue} hymnalName={ensureExists(hymn.hymnal)} songNumber={hymn.number}/>
              : null
          }
        </div>
        <div style={{...(hidePlayer && {display: 'none'})}}>
          {renderPlayerUI()}
        </div>
      </div>
    )
  }


  function renderStickyFooter() {
    return (
      <div id="stickyFooter" hidden={hidePlayer} style={isWKWebView() ? {bottom: 0} : {bottom: 20}}>

        <div  style={{display: !isDesktop || display !== Display.PDF ? "none" : "flex"}} id="desktopBackPrompt" onClick={() => setDisplay(Display.SongSettings)}>
          <ArrowLeft  className="arrowPointer"/>
        </div>

        <div id="dotsContainer" style={isDesktop ? {display: 'none'} : {display:'flex'}}>
         <div
           style={display === Display.PDF ? {backgroundColor: "#b0b0b0"} : {backgroundColor: "#606060"}}
           onClick={() => setDisplay(Display.SongSettings)}
           className="dot"/>
         <div style={{width: 10}}/>

         <div
           style={display === Display.SongSettings ? {backgroundColor: "#b0b0b0"} : {backgroundColor: "#888888"}}
           onClick={() => setDisplay(Display.PDF)}
           className="dot"/>
        </div>

        <div  style={{display: !isDesktop || display !== Display.SongSettings ? "none" : "flex"}} id="desktopPdfPrompt" onClick={() => setDisplay(Display.PDF)}>
          <div  className="promptText">PDF</div>
          <ArrowRight  className="arrowPointer"/>
        </div>
      </div>
    )
  }

  const swipeRightHandler = useSwipeable({
    onSwipedRight: () => setDisplay(Display.SongSettings),
  });

  const onClosePdf = useCallback(() => setDisplay(Display.SongSettings), [])

  return (
    <div {...swipeRightHandler}>
      <div style={{transform: display === Display.PDF && !hidePlayer ? "translateY(-60px)" : "", transition: "transform 350ms"}}>
        <ModalNotification close={() => setModal('')} popup={modal} hymn={hymn}/>
        <Header
          title={ensureExists(title)}
          hymn={hymn}
          onBack={onBack}
          onKebabClick={() => setSlideMenu(true)}
        />
      </div>
      <SlideMenu
        show={slideMenu}
        showModal={user === 'guest' ? () => setModal('login-report') : () => setModal('report-player') }
        close={() => setSlideMenu(false)}
        displayPdf={() => setDisplay(Display.PDF)}
        hymn={hymn}/>
      <div id="playerPage" style={{left: display === Display.PDF && !hidePlayer ? "-100%" : 0}}>
        {renderControls()}
        <PdfViewerPage hymn={hymn} onClose={onClosePdf}/>
      </div>
      {renderStickyFooter()}
    </div>
  );
}

const VocalsButtonBlock = styled.div<{$visible?: boolean}>`
  display: ${props => props.$visible ? 'flex' : 'none'};
  justify-content: center;
`;

const VocalsButtonContentWrapper = styled.div<{$enabled: boolean}>`
  background-color: ${props => props.$enabled ? 'rgba(82, 0, 187, 0.2)' : 'inherit'};
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: inherit;
`;

function fixVoiceValues(voiceValues: VoiceValues): VoiceValues {
  // TODO(hewitt): Customers have reported the crash `TypeError: The provided value is non-finite`
  //               when adjusting vocal volumes.  Thus, it appears that infinite volume values are
  //               being recorded -> we attempt to prevent this here.
  return Object.fromEntries(Object.entries(voiceValues).map(([key, value]) =>
    [key, Math.min(1, value)]
  )) as unknown as VoiceValues;
}