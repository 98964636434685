import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {OnboardingPages} from '../../common/pages';

export const ShareMusicLeaderInstructions = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.SurveyHouseholdSchedule);
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={22}>
          Wonderful!<br/><br/>Please fill out and send the newly created email to someone in your church.
        </Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
