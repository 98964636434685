import autoBind from 'auto-bind';
import {
  createAudioContext,
  generateWavBlobForMIDI,
  loadInstrument,
  MP3GeneratorResult,
} from './util/instrument';
import {addListener, cacheGet, CacheKey, DefaultValues, removeListener} from "./data/client_cache";
import * as midiManager from "midi-file";
import {MidiData, MidiSetTempoEvent} from "midi-file";
import {DualAudioElement} from "./util/dual_audio_element";

const msBetweenMP3Refreshes = 500;
const microsecondsPerMinute = 60000000;
const defaultTempo = 100;

export class MidiFilePlayer {
  audioContext = createAudioContext();
  initialTime: string | undefined = '0:00';
  interval: number | undefined;
  mostRecentTick: number | undefined;
  mp3RefreshDebounceTimer: NodeJS.Timeout | undefined;
  mostRecentMP3RefreshTime: number;
  isMidiFileLoaded: boolean = false;
  isInstrumentLoaded?: boolean;
  playing?: boolean;
  experiencingUserInputOnTimeSlider: boolean = false;
  initialTempo = defaultTempo;
  midiFilePath: string;
  audioElement: DualAudioElement;
  songReady: (tempo: number) => void;
  onNetworkError: (response?: Response) => void;
  endOfFile: () => void;
  voiceValues = DefaultValues[CacheKey.VoiceValues];
  modulationHalfSteps: () => number;
  private _midiData: MidiData | undefined;

  constructor(
    midiFilePath: string,
    songReady: (tempo: number) => void,
    onNetworkError: (response?: Response) => void,
    endOfFile: () => void,
    modulationHalfSteps: () => number,
    logAllEvents?: boolean,
  ) {
    autoBind(this);

    this.midiFilePath = midiFilePath;
    this.audioElement = new DualAudioElement();
    this.songReady = songReady;
    this.onNetworkError = onNetworkError;
    this.endOfFile = endOfFile;

    this.audioElement.addEventListener("ended", this._onEndOfFile.bind(this));
    this.audioElement.addEventListener("canplaythrough", this._onCanPlayThrough.bind(this));

    if (logAllEvents) {
      this.audioElement.addEventListener('audioprocess', () => console.log('audioprocess'))
      this.audioElement.addEventListener('canplay', () => console.log('canplay'));
      this.audioElement.addEventListener('canplaythrough', () => console.log('canplaythrough'));
      this.audioElement.addEventListener('complete', () => console.log('complete'));
      this.audioElement.addEventListener('durationchange', () => console.log('durationchange'));
      this.audioElement.addEventListener('emptied', () => console.log('emptied'));
      this.audioElement.addEventListener('ended', () => console.log('ended'));
      this.audioElement.addEventListener('loadeddata', () => console.log('loadeddata'));
      this.audioElement.addEventListener('loadedmetadata', () => console.log('loadedmetadata'));
      this.audioElement.addEventListener('loadstart', () => console.log('loadstart'));
      this.audioElement.addEventListener('pause', () => console.log('pause'));
      this.audioElement.addEventListener('play', () => console.log('play'));
      this.audioElement.addEventListener('playing', () => console.log('playing'));
      this.audioElement.addEventListener('ratechange', () => console.log('ratechange'));
      this.audioElement.addEventListener('seeked', () => console.log(`seeked ${this.audioElement.currentTime}`));
      this.audioElement.addEventListener('seeking', () => console.log('seeking'));
      this.audioElement.addEventListener('stalled', () => console.log('stalled'));
      this.audioElement.addEventListener('suspend', () => console.log('suspend'));
      this.audioElement.addEventListener('timeupdate', () => console.log('timeupdate'));
      this.audioElement.addEventListener('volumechange', () => console.log('volumechange'));
      this.audioElement.addEventListener('waiting', () => console.log('waiting'));
    }

    this.mostRecentMP3RefreshTime = Date.now();
    this._loadMidiFile();

    this.interval = window.setInterval(this.updateTimeSlider, 1000);
    loadInstrument(async () => {
      await this._refreshMP3();
      this.isInstrumentLoaded = true;
      if (this.isMidiFileLoaded) {
        this.songReady(this.initialTempo)
      }
    });

    this._refreshVoiceValues();
    addListener(CacheKey.VoiceValues, this._refreshVoiceValues);

    this.modulationHalfSteps = modulationHalfSteps;
  }

  private _refreshVoiceValues() {
    this.voiceValues = cacheGet(CacheKey.VoiceValues);
  }

  private _onEndOfFile() {
    this.endOfFile()
  }

  private _onCanPlayThrough() {
    if (!this.isMidiFileLoaded) {
      this.isMidiFileLoaded = true;
      if (this.isInstrumentLoaded) {
        this.songReady(this.initialTempo);
      }
    }
  }

  restart() {
    this.audioElement.currentTime = 0;
    this.updateTimeSlider();
  }

  setLocationPercentage(percent: number) {
    this.audioElement.currentTime = this.audioElement.duration * (percent / 100);
  }

  private static _getTimeValue (secondsElapsed: number): string {
    const minutes = Number(Math.trunc(secondsElapsed / 60));
    const seconds = (Math.round(secondsElapsed % 60)).toString().padStart(2,'0');
    return minutes + ':' + seconds;
  }

  getTempoValue(): number {
    const tempoSliderElement = document.getElementById('tempo-slider');

    if (tempoSliderElement) {
      const currentTempo = Number((tempoSliderElement as HTMLInputElement).value);
      return currentTempo * this.initialTempo;
    }

    return defaultTempo;
  }

  setNewTime() {
    this.experiencingUserInputOnTimeSlider = false;
    const timeSliderElement = document.getElementById('time-slider');
    const sliderValue = Number((timeSliderElement as HTMLInputElement).value) / 100;
    const newTime = sliderValue * this.audioElement.duration;
    this.audioElement.currentTime = newTime;
  }

  displayTime() {
    this.experiencingUserInputOnTimeSlider = true;
    const timeSliderElement = document.getElementById('time-slider');
    const timeOutputElement = document.getElementById('time-output');
    const secondsSelected = ((Number((timeSliderElement as HTMLInputElement).value) / 100) * this.audioElement.duration);
    (timeOutputElement as HTMLOutputElement).innerHTML = MidiFilePlayer._getTimeValue(secondsSelected);
  }

  private _debouncedMP3Refresh() {
    const msSinceLastMP3Refresh = Date.now() - this.mostRecentMP3RefreshTime;
    if (this.mp3RefreshDebounceTimer) {
      clearTimeout(this.mp3RefreshDebounceTimer);
    }
    this.mp3RefreshDebounceTimer = setTimeout(
      this._refreshMP3,
      Math.max(0, msBetweenMP3Refreshes - msSinceLastMP3Refresh),
    );
  }

  onTempoOrVolumeChange() {
    this.displayTempo();
    this._debouncedMP3Refresh();
  }

  displayTempo() {
    const tempoOutputElement = document.getElementById('tempo-output');
    if (!tempoOutputElement) {
      return;
    }
    (tempoOutputElement as HTMLInputElement).innerHTML = (Math.round(this.getTempoValue())).toString();
  }

  private get _finishedLoading() {
      return this.isInstrumentLoaded && this.isMidiFileLoaded
  }

  private _getCurrentPercent(): number {
    return (this.audioElement.currentTime / this.audioElement.duration) * 100;
  }

  private _getTimeOutput(): string {
    const currentTime = (this._getCurrentPercent() / 100) * this.audioElement.duration;

    if (this._finishedLoading) {
      return MidiFilePlayer._getTimeValue(currentTime);
    }
    else {
      return 'LOADING'
    }
  }

  play() {
    void this.audioElement.play();
    this.playing = true;
    this.mostRecentTick = undefined;
  }

  pause() {
    this.audioElement.pause();
    this.playing = false;
    this.updateTimeSlider();
  }

  private _loadMidiFile() {

    const loadArrayBuffer = async (buffer: ArrayBuffer) => {
      const midiArray = new Uint8Array(buffer);
      this._midiData = midiManager.parseMidi(midiArray);
      const tempoEvent = this._midiData.tracks[0].find(event => event.type === 'setTempo') as MidiSetTempoEvent | undefined;
      if (tempoEvent) {
        this.initialTempo = Math.round(microsecondsPerMinute / tempoEvent.microsecondsPerBeat);
      }
      await this._refreshMP3();
      this.initialTime = '0:00';
    }

    fetch(this.midiFilePath)
        .then(
          response => {
            if (!response.ok) {
              this.onNetworkError(response);
              return;
            }
            response.arrayBuffer().then(buffer => loadArrayBuffer(buffer));
          },
          reason => console.log(reason)
        );
  }

  private async _refreshMP3() {
    if (!this._midiData) {
      return;
    }
    const tempo = this.getTempoValue();
    const {audioBlob, result} = await generateWavBlobForMIDI(
      this._midiData,
      this.audioContext,
      tempo,
      this.modulationHalfSteps(),
      this.voiceValues,
    );
    if (result === MP3GeneratorResult.Failure) {
      this.onNetworkError();
      return;
    }
    if (!audioBlob) {
      return;
    }
    this.audioElement.src = audioBlob;
    this.mp3RefreshDebounceTimer = undefined;
    this.mostRecentMP3RefreshTime = Date.now();
  }

  updateTimeSlider() {
    if (
      this._finishedLoading &&
      !this.experiencingUserInputOnTimeSlider &&
      this.playing
    ) {
      const timeSliderElement = document.getElementById('time-slider');
      const timeOutputElement = document.getElementById('time-output');
      if (timeSliderElement) {
        (timeSliderElement as HTMLOutputElement).value = this._getCurrentPercent().toString();
      }
      if (timeOutputElement) {
        (timeOutputElement as HTMLOutputElement).innerHTML = this._getTimeOutput();
      }
    }
  }

  destroy() {
    clearInterval(this.interval);
    removeListener(CacheKey.VoiceValues, this._refreshVoiceValues);

    // stop music when back button is pressed
    if (this.playing) {
      this.pause();
    }
  }
}