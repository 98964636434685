import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components/macro';
import {isInsideAppleAppStoreApp} from '../../util/billing';
import {ReactComponent as LargeBackArrow} from '../../assets/arrow-back.svg';
import {ReactComponent as SmallBackArrow} from '../../assets/arrow-previous-left-icon.svg';
import {ReactComponent as CloseIcon} from '../../assets/close.svg';
import {getPlatform, Platform} from '../../util/platform';

const maxHeight = '1000px';
const maxWidth = '500px';
const minHeight = '450px';

interface Props {
  mainContent: React.ReactElement;
  footerContent: React.ReactElement;
  suppressBackButton?: boolean;
  onEnterKeypress?: () => void;
  containsInputElement?: boolean;
  isSmallBackButton?: boolean;
  onBack?: () => void;
  alignTop?: boolean;
  onClose?: () => void;
}

export const Frame = ({
  mainContent,
  footerContent,
  suppressBackButton,
  onEnterKeypress,
  containsInputElement,
  isSmallBackButton,
  onBack,
  alignTop,
  onClose,
}: Props) => {
  const onBackCallback = useCallback(() => {
    if (onBack) {
      onBack();
    } else {
      window.history.back();
    }
  }, [onBack]);

  const onKeyDown = useCallback((evt: KeyboardEvent) => {
    if (evt.code === 'Enter') {
      onEnterKeypress?.();
    }
  }, [onEnterKeypress]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);

  // The Android mobile keyboard contains a "Go" button intended to submit a <form>.
  // Including a <form> in the page causes the browser to reload server content on back & submit.
  // Thus, we squeeze the page vertically for Android so that the Continue button is always visible.
  // If we similarly squeeze the page on iOS, the page gets scrolled off the top by the keyboard.  :/
  const squeezePageHeight = containsInputElement && getPlatform() === Platform.Mobile;

  let height: string;
  if (squeezePageHeight) {
    height = '0';
  } else if (isInsideAppleAppStoreApp()) {
    height = '100vh'
  } else {
    height = 'var(--view-height, 100vh)';
  }

  return (
    <OuterWrapper $squeezePageHeight={squeezePageHeight} style={{height: height}}>
      <InnerWrapper $squeezePageHeight={squeezePageHeight}>
        <MainWrapper $alignTop={alignTop}>
          {!suppressBackButton && (
            <BackButton key='back' onClick={onBackCallback}>
              {
                isSmallBackButton
                  ? <SmallBackArrow style={{color: 'var(--color-text-light)', height: '24px', paddingLeft: '10px', paddingTop: '4px'}}/>
                  : <LargeBackArrow style={{color: 'var(--color-text-light)', height: '40px'}}/>
              }
            </BackButton>
          )}
          {onClose && (
            <CloseButton key='close' onClick={onClose} style={{fill: 'var(--color-text-light)', width: '50px'}}>
              <CloseIcon />
            </CloseButton>
          )}
          {mainContent}
        </MainWrapper>
        <FooterWrapper>
          {footerContent}
        </FooterWrapper>
      </InnerWrapper>
      <LandscapeMessage>
        Please rotate your phone to portrait
      </LandscapeMessage>
    </OuterWrapper>
  );
}


const OuterWrapper = styled.div<{$squeezePageHeight: boolean | undefined}>`
  display: grid;
  place-items: ${props => props.$squeezePageHeight ? 'start' : 'center'};
  width: 100vw;
  height: 100%;
  background-color: var(--color-background);
`;

const InnerWrapper = styled.div<{$squeezePageHeight: boolean | undefined}>`
  display: grid;
  position: relative;
  grid-template-rows: minmax(${props => props.$squeezePageHeight ? '200px' : '250px'}, auto) max-content;
  font-size: min(max(4.5vw, 1em), 1.5em);

  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: min(${maxHeight}, ${props => props.$squeezePageHeight ? '0' : (isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)')});
  width: min(${maxWidth}, 100%);

  // hide content in landscape
  @media (max-height: ${minHeight}) and (orientation:landscape) and (min-aspect-ratio: 13/9) {
    display: none;
  }

  // TODO(hewitt): Prevent screen rotation during onboarding
  //--screen-height-not-rotated: max(100vw, var(--view-height, 100vh));
  //--screen-width-not-rotated: min(100vw, var(--view-height, 100vh));
  //height: var(--screen-height-not-rotated);
  //width: var(--screen-width-not-rotated);
  //@media (orientation: landscape) {
  //  transform-origin: calc(100vw / 2) calc(100vh / 2);
  //  // transform-origin: calc(100vh / 2) calc(100vw / 2);
  //  transform: rotate(-90deg);
  //}
  // tried placing the following in the page header
  // <meta http-equiv="ScreenOrientation" content="autoRotate:disabled">
`;

const MainWrapper = styled.div<{$alignTop?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.$alignTop ? 'start' : 'center'};
`;

const LandscapeMessage = styled.div`
  display: none;
  font-size: 1.3em;
  @media (max-height: ${minHeight}) and (orientation:landscape) and (min-aspect-ratio: 13/9) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 1.5em;
  padding-bottom: 40px; 
  background-color: var(--color-background);
`;

const BackButton = styled.button`
  position: absolute;
  top: 15px;
  left: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
