import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {useChurch} from '../../data/use_church';

export const ChurchWelcome = () => {
  const {church} = useChurch();
  const onContinue = useCallback(() => {
    navigateTo('/');
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={24}>Congratulations, your household is now associated with {church?.name}!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
