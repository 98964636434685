import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message} from './common';
import {Frame} from './frame';
import styled from 'styled-components/macro';
import {ReactComponent as AppleBadgeIcon} from "../../assets/apple-download-white.svg";
import {ReactComponent as GoogleBadgeIcon} from "../../assets/google-play-download-white.svg";
import {ReactComponent as InternetIcon} from "../../assets/internet.svg";
import {Button} from './button';
import {isInsideMobileApp} from '../../util/billing';

export const Download = () => {
  const onApple = useCallback(() => {
    window.location.href='https://apps.apple.com/us/app/sing-your-part/id1483413949';
  }, []);
  const onGoogle = useCallback(() => {
    window.location.href='https://play.google.com/store/apps/details?id=singyourpart.app.twa';
  }, []);
  const onWeb = useCallback(() => {
    setTimeout(() => navigateTo('/'));
  }, []);

  // never show download page inside the app
  if (isInsideMobileApp()) {
    setTimeout(() => navigateTo('/'));
    return null;
  }

  return (
    <Frame
      mainContent={(
        <Announcement $widthInChars={12}>Three ways to<br/>Sing Your Part</Announcement>
      )}
      footerContent={(
        <>
          <OptionButton onClick={onApple} notPrimary={true}><AppleBadge/></OptionButton>
          <OptionButton onClick={onGoogle}><GoogleBadge/></OptionButton>
          <OptionButton onClick={onWeb}>
            <WebButtonContent>
              <InternetIconWrapper/>
              <WebButtonText>On the Web</WebButtonText>
            </WebButtonContent>
          </OptionButton>
        </>
      )}
      onEnterKeypress={onWeb}
      suppressBackButton={true}
      onClose={onWeb}
    />
  );
};

const Announcement = styled(Message)`
  padding-top: 50px;
  font-size: 2em;
`;

const OptionButton = styled(Button)`
  height: 75px;
`;

const WebButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-family: Jost-SemiBold, sans-serif;
  font-size: 0.75em;
  color: black;
  text-decoration: none;
  padding: 15px 21px;
`;

const AppleBadge = styled(AppleBadgeIcon)`
  fill: var(--color-text);
  width: 200px;
`;

const GoogleBadge = styled(GoogleBadgeIcon)`
  fill: var(--color-text);
  width: 200px;
`;

const InternetIconWrapper = styled(InternetIcon)`
  width: 36px;
  fill: var(--color-text);
`;

const WebButtonText = styled.div`
  color: var(--color-text);
  font-family: Jost-SemiBold, sans-serif;
  font-size: 24px;
`;
