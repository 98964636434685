import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {EditAllHouseholdInfoQueryString, Message} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {OnboardingPages} from '../../common/pages';
import {withSearchParams} from '../../common/util';

export const HouseholdStart = () => {
  const onContinue = useCallback(() => {
    navigateTo(withSearchParams(OnboardingPages.HouseholdLastName, EditAllHouseholdInfoQueryString));
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={20}>Let's create a household so your family can join!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
}
