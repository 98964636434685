export enum Device {
  iOS = 'ios',
  Android = 'android',
  Other = 'other',
}

export enum Platform {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export function getDevice(): Device {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    return Device.iOS;
  } else if (userAgent.includes('android')) {
    return Device.Android;
  }
  return Device.Other;
}

export function getPlatform(): Platform {
  return getDevice() === Device.Other ? Platform.Desktop : Platform.Mobile;
}
