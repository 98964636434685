import React from 'react';
import styled from 'styled-components/macro';
import {CacheKey, Guest} from '../../data/client_cache';
import { confirm } from '../../util/confirm'
import {Subscription} from '../../common/model';
import {isInsideAppleAppStoreApp, requestIOSAppVersion} from '../../util/billing';
import '../settings_page.css'

export const LocalStoragePage = () => (
  <LocalStorageWrapper>
    <LocalStorageTable/>
  </LocalStorageWrapper>
);

const LocalStorageWrapper = styled.div`
  background-color: var(--color-background);
  height: 100%;
  overflow: auto;
`;

const ClearAllButton = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.2em;
  border-radius: 15px;
  padding: 2px 12px;
  margin: 20px;
  border: none;
  background-color: red;
  color: white;
  cursor: pointer;
`

const LocalStorageTable = () => {
  const [refresh, setRefresh] = React.useState("");
  if (refresh) {
  } // eliminate warning
  return (
    <ScrollContainer>
      <ClearAllButton onClick={clearAllSettings(() => setRefresh("All"))}>Clear All Settings</ClearAllButton>
      <LocalStorageTableStyles>
        {
          Object.keys(localStorage).sort((lhs, rhs) => lhs.localeCompare(rhs)).map((key) => (
            <StorageRow key={key}>
              <Cell key={key + '0'} className="localStorageKey">{key}</Cell>
              <Cell key={key + '1'}>{formatLocalStorageValue(key, localStorage[key])}</Cell>
              <DeleteButton key={key + '2'} storageKey={key} refresh={() => setRefresh(key)}/>
            </StorageRow>
          ))
        }
      </LocalStorageTableStyles>
    </ScrollContainer>
  );
}

function clearAllSettings(refresh: () => void): () => Promise<void> {
  return async () => {
    if (await confirm({confirmation: <span>Delete all keys?</span>})) {
      localStorage.clear();
      localStorage.setItem(CacheKey.User, Guest);
      if (isInsideAppleAppStoreApp()) {
        requestIOSAppVersion();
      }
      refresh();
    }
  }
}

function formatLocalStorageValue(key: string, value: string): string {
  let formattedValue = value;
  const abbreviateValue = key.endsWith(CacheKey.Credentials);
  try {
    let object = JSON.parse(value);
    if (key === CacheKey.ClientSubscription) {
      const subscription = object as Subscription;
      object = {
        ...object,
        ...(
          subscription.subscriptionExpirationTimestamp &&
          {
            expirationDate: new Date(subscription.subscriptionExpirationTimestamp).toString(),
            inFuture: Boolean(new Date(subscription.subscriptionExpirationTimestamp).getTime() > Date.now()),
          }
        )
      };
    }
    formattedValue = JSON.stringify(object, null, 2);
  } catch { }
  return abbreviateValue ? formattedValue.slice(0, 20) + '...' : formattedValue
}

const ScrollContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: auto;
`

async function deleteLocalStorageKey(key: string, refresh: () => void) {
  if (await confirm({confirmation: <span>Delete key?<br/>{key}</span>})) {
    localStorage.removeItem(key);
    refresh();
  }
}

const DeleteButton = ({storageKey, refresh}: {storageKey: string; refresh: () => void}) => (
  <DeleteButtonStyles onClick={() => void deleteLocalStorageKey(storageKey, refresh)}>X</DeleteButtonStyles>
);

const DeleteButtonStyles = styled.button`
  font-family: Jost-SemiBold, sans-serif;
  font-size: 1em;
  color: var(--color-text-button);
  background-color: red;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 10px;
  height: 100%;
  margin: auto 0;
`

const LocalStorageTableStyles = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr auto;
  gap: 5px 5px;
  background-color: var(--color-background);
  padding: 5px;
`

const StorageRow = styled.div`
  display: contents;
  &:nth-of-type(odd) div {
    background-color: var(--color-background-table-banding);
  }
`;

const Cell = styled.div`
  text-align: left;
  padding: 5px 0;
  white-space: pre-wrap; /* respect spaces & newlines in JSON.stringify output */
`
