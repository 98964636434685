import {ensureUnreachable} from '../common/util';
import {Demo} from './onboarding/demo';
import {Questions} from './onboarding/questions';
import {Start} from './onboarding/start';
import {HouseholdFind} from './onboarding/household_find';
import {HouseholdWelcome} from './onboarding/household_welcome';
import {HouseholdStart} from './onboarding/household_start';
import {HouseholdLastName} from './onboarding/household_last_name';
import {HouseholdEmail} from './onboarding/household_email';
import {HouseholdNotFound} from './onboarding/household_not_found';
import {HouseholdJoin} from './onboarding/household_join';
import {HouseholdCreate} from './onboarding/household_create';
import {ChurchChoice} from './onboarding/church_choice';
import {ChurchWelcome} from './onboarding/church_welcome';
import {AllowNotifications} from './onboarding/allow_notifications';
import {Awesome} from './onboarding/awesome';
import {SurveyConfidenceLevel} from './onboarding/survey_confidence_level';
import {SurveyDiscovery} from './onboarding/survey_discovery';
import {SurveyExpectedUsage} from './onboarding/survey_expected_usage';
import {ShareMusicLeader} from './onboarding/share_music_leader';
import {ShareFriendsFamily} from './onboarding/share_friends_family';
import {SurveyTimeInvestment} from './onboarding/survey_time_investment';
import {SurveyHouseholdSchedule} from './onboarding/survey_household_schedule';
import {ShareMusicLeaderInstructions} from './onboarding/share_music_leader_instructions';
import {SurveyHouseholdNotifications} from './onboarding/survey_household_notifications';
import {SubscribeStart} from './onboarding/subscribe_start';
import {SubscribePayment} from './onboarding/subscribe_payment';
import {Download} from './onboarding/download';
import {SubscribeThankYou} from './onboarding/subscribe_thank_you';
import {OnboardingPages, Pages} from '../common/pages';
import {SubscribeFindHousehold} from './onboarding/subscribe_find_household';

function getOnboardingPage(): OnboardingPages {
  const page = window.location.pathname.match(`/${Pages.Onboarding}/(?<page>[^/]+)`)?.groups?.page;
  return page && Object.values(OnboardingPages).includes(page as OnboardingPages)
    ? page as OnboardingPages
    : OnboardingPages.Demo;
}

export const OnboardingPage = () => {
  const page = getOnboardingPage();
  switch (page) {
    case OnboardingPages.AllowNotifications:
      return <AllowNotifications />;
    case OnboardingPages.Awesome:
      return <Awesome/>;
    case OnboardingPages.ChurchChoice:
      return <ChurchChoice/>;
    case OnboardingPages.ChurchWelcome:
      return <ChurchWelcome/>;
    case OnboardingPages.SurveyConfidenceLevel:
      return <SurveyConfidenceLevel/>;
    case OnboardingPages.Demo:
      return <Demo/>;
    case OnboardingPages.SurveyDiscovery:
      return <SurveyDiscovery/>;
    case OnboardingPages.SurveyHouseholdSchedule:
      return <SurveyHouseholdSchedule/>;
    case OnboardingPages.SurveyHouseholdNotifications:
      return <SurveyHouseholdNotifications/>;
    case OnboardingPages.SurveyExpectedUsage:
      return <SurveyExpectedUsage/>;
    case OnboardingPages.SurveyTimeInvestment:
      return <SurveyTimeInvestment/>;
    case OnboardingPages.HouseholdStart:
      return <HouseholdStart/>;
    case OnboardingPages.HouseholdLastName:
      return <HouseholdLastName/>
    case OnboardingPages.HouseholdEmail:
      return <HouseholdEmail/>;
    case OnboardingPages.HouseholdNotFound:
      return <HouseholdNotFound/>;
    case OnboardingPages.HouseholdCreate:
      return <HouseholdCreate/>;
    case OnboardingPages.HouseholdJoin:
      return <HouseholdJoin/>;
    case OnboardingPages.HouseholdFind:
      return <HouseholdFind/>;
    case OnboardingPages.HouseholdWelcome:
      return <HouseholdWelcome/>;
    case OnboardingPages.Questions:
      return <Questions/>;
    case OnboardingPages.ShareFriendsFamily:
      return <ShareFriendsFamily/>;
    case OnboardingPages.ShareMusicLeader:
      return <ShareMusicLeader/>;
    case OnboardingPages.ShareMusicLeaderInstructions:
      return <ShareMusicLeaderInstructions/>;
    case OnboardingPages.Start:
      return <Start/>;
    case OnboardingPages.SubscribeStart:
      return <SubscribeStart/>;
    case OnboardingPages.SubscribeFindHousehold:
      return <SubscribeFindHousehold/>;
    case OnboardingPages.SubscribePayment:
      return <SubscribePayment/>;
    case OnboardingPages.SubscribeThankYou:
      return <SubscribeThankYou/>;
    case OnboardingPages.Download:
      return <Download/>;
    default:
      ensureUnreachable(page);
  }
};
