import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';

export const HouseholdWelcome = () => {
  const {church} = useChurch();
  const onContinue = useCallback(() => {
    if (church) {
      navigateTo('/');
      return;
    }
    navigateTo(OnboardingPages.ChurchChoice);
  }, [church]);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={20}>Welcome, your household is ready to sing!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
