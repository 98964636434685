import React, {useCallback} from 'react';
import {Frame} from './frame';
import {Message} from './common';
import {Button} from './button';
import {navigateTo} from '../../util/use_location';
import {OnboardingPages} from '../../common/pages';

export const Awesome = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.SurveyHouseholdSchedule);
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={16}>Awesome!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
