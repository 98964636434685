import React, {useCallback} from 'react';
import {Frame} from './frame';
import {Message} from './common';
import {Button} from './button';
import {navigateTo} from '../../util/use_location';
import {OnboardingPages} from '../../common/pages';

const shareData = {
  title: "Sing Your Part",
  text: "Practice singing with Sing Your Part!",
  url: "https://singyourpart.org",
};

export const ShareFriendsFamily = () => {
  const onShare = useCallback(async () => {
    await navigator.share(shareData);
    navigateTo(OnboardingPages.HouseholdWelcome);
  }, []);
  const onSkip = useCallback(() => {
    navigateTo(OnboardingPages.HouseholdWelcome);
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={18}>Invite your friends & family to join!</Message>
      )}
      footerContent={(
        <>
          <Button onClick={onShare}>SHARE</Button>
          <Button onClick={onSkip}>MAYBE LATER</Button>
        </>
      )}
      onEnterKeypress={onShare}
    />
  );
};
