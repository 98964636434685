import {useCache} from '../data/use_cache';
import {CacheKey} from '../data/client_cache';
import {useLocation} from '../util/use_location';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import * as server_api from '../../src/common/server_api';
import styled from 'styled-components/macro';
import {BackgroundGradient} from '../shared';
import {DeprecatedDownloadPage, DeprecatedHouseholdBillingPage, isInsideMobileApp} from '../util/billing';
import {useHousehold} from '../data/use_household';
import {AppConfig} from '../common/model';
import {getOnboardingPath, OnboardingPages} from '../common/pages';
import {useAppConfig} from '../data/app_config';

export function ChurchHouseholdInvite() {
  const {location} = useLocation();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const {pathname, search} = location;
  const churchId = new URLSearchParams(search).get('church-id');
  const [finished, setFinished] = useState(false);
  const [church] = useCache(CacheKey.Church);
  const enableOnboarding = useAppConfig(AppConfig.EnableOnboarding);

  useEffect(() => {
    void (async () => {
      if (householdToken && churchId) {
        await server_api.setHouseholdChurch(householdToken, {id: Number(churchId)});
        setFinished(true);
      }
    })();
  }, [householdToken, churchId]);

  const onContinue = useCallback(() => {
    if (isInsideMobileApp()) {
      window.location.href = '/';
      return;
    }
    window.location.href = enableOnboarding ? getOnboardingPath(OnboardingPages.Download) : DeprecatedDownloadPage;
  }, [enableOnboarding]);

  // @ts-ignore
  if (!churchId || isNaN(churchId)) {
    window.location.href = '/';
    return null;
  }

  if (!householdToken) {
    // TODO(hewitt): route through new onboarding household creation and back to this page
    // redirect to household billing page to gather household information
    window.location.href = DeprecatedHouseholdBillingPage + '?return-to-url=' + encodeURIComponent(pathname + search);
  }

  if (!finished) {
    return <DisplayMessage message={[<Text>Updating household church...</Text>]}/>
  }

  return <DisplayMessage message={[
    <Text>Congratulations!</Text>,
    <Text>Your household is now associated with {church?.name}</Text>,
    <Button onClick={onContinue}>Continue</Button>
  ]}/>
}

function DisplayMessage({message}: {message: ReactElement[]}) {
  return (
    <BackgroundGradient>
      <Wrapper>
        {message}
      </Wrapper>
    </BackgroundGradient>
  );
}

const Text = styled.div`
  max-width: 30ch;
`

const Wrapper = styled.div`
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
  padding: 0 5px;
`

const Button = styled.button`
  font-family: JostSemiBold, "Arial", sans-serif;
  font-size: 1em;
  padding: 5px 20px;
  border-radius: 50px;
  border: none;
  background-color: white;
  color: #35017F;
  cursor: pointer;
`
