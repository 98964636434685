import {HymnalWithHymns} from '../pages/hymns_list';

export const defaultSongList: HymnalWithHymns[] = [
  {
    id: 0,
    name: 'This Sunday',
    hymns: [
      {hymnal: 'Cantus Christi (2020)', number: 408, title: 'A Mighty Fortress Is Our God'},
      {hymnal: 'Cantus Christi (2020)', number: 461, title: 'Amazing Grace!'},
      {hymnal: 'Cantus Christi (2020)', number: 447, title: 'And Can It Be That I Should Gain'},
      {hymnal: 'Cantus Christi (2020)', number: 317, title: 'Holy, Holy, Holy!'},
    ],
  }
];
