import React from 'react';
import styled from 'styled-components/macro';
import {AppStoreUrl, appUpdateNeeded, DeprecatedHouseholdBillingPage, DeprecatedSubscribePage} from './billing';
import {AppConfig, HouseholdStatus} from '../common/model';
import {useHousehold} from '../data/use_household';
import {getOnboardingPath, OnboardingPages} from '../common/pages';
import {useAppConfig} from '../data/app_config';

export const SignUpButton = ({subscribeButton}: {subscribeButton?: boolean}) => {
  const enableOnboarding = useAppConfig(AppConfig.EnableOnboarding);
  const {household} = useHousehold();
  const householdStatus = household?.status;
  let message = 'Sign Up';
  let href = enableOnboarding ? getOnboardingPath() : DeprecatedHouseholdBillingPage;
  if (appUpdateNeeded()) {
    message = 'Update';
    href = AppStoreUrl;
  } else if (subscribeButton || householdStatus === HouseholdStatus.Unsubscribed) {
    message = 'Subscribe';
    href = enableOnboarding ? getOnboardingPath(OnboardingPages.SubscribeStart) : DeprecatedSubscribePage;
  }
  return <SignUpButtonStyle href={href}>{message}</SignUpButtonStyle>
}

export const OptInButton = ({onClick}: {onClick: () => void}) => {
  return <SignUpButtonStyle onClick={onClick}>Opt In</SignUpButtonStyle>
}

const SignUpButtonStyle = styled.a`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1em;
  color: white;
  background-color: #5200BB;
  padding: 3px 12px;
  border-radius: 30px;
  margin-right: 8px;
  cursor: pointer;
  text-wrap: nowrap;
`;
