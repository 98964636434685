import * as md5 from './md5';
import {DateString} from "./date_string";

export interface Favorite {
  hymnalName: string;
  songNumber: number;
}

export interface TransposedSong {
  hymnalName: string;
  songNumber: number;
  transposeValue: number;
}

export interface SongView {
  hymnalName: string;
  songNumber: number;
  durationMs: number;
}

export interface User {
  email: string;
  name: string;
}

export interface Household {
  token?: string;
  familyLastName?: string;
  householdEmail?: string;
  churchId?: number;
  subscriptionExpirationTimestamp?: number;
  status?: HouseholdStatus;
  mostRecentSyncTimestamp?: number;
}

export interface Subscription {
  householdId?: number;
  paymentProcessor?: PaymentProcessor,
  email?: string;
  customerId?: string;
  subscriptionExpirationTimestamp?: number;
  productId?: string;
}

export enum PaymentProcessor {
  Apple = 'Apple',
  Google = 'Google',
  Stripe = 'Stripe',
}

export enum GoogleProductIds {
  Annual = 'annualfamily',
  Monthly = 'montlyfamily', // note the misspelling of "monthly"
}

export enum HouseholdStatus {
  Unsubscribed = 'Unsubscribed',
  Subscribed = 'Subscribed',
}

export interface Church extends Partial<ChurchTokens> {
  id: number;
  name: string;
  location: string;
  type?: OrganizationType; // TODO(hewitt): should eventually be a required field, but requires billing work
  city?: string;
  state?: string;
  country?: string;
  deleted?: boolean;
  renewalDate?: number;
  areHouseholdsSubscribed?: true; // true or undefined
  hymnals?: Hymnal[];
  primaryHymnalId?: number;
  mostRecentSyncTimestamp?: number;
}

export enum OrganizationType {
  Church = 'Church',
  School = 'School',
  SmallGroup = 'Small Group',
}

export interface ChurchTokens {
  token: string;
  adminToken: string;
}

export interface ChurchWithHymnalsAndAdmins extends Church {
  hymnals: Hymnal[];
  adminEmails: string[];
}

export interface ChurchAdminCredentials {
  churchId: number,
  churchAdminToken: string,
  userToken: string,
}

export interface Hymnal {
  id: number;
  name: string;
  isInternal?: boolean;
  isChurchSpecific?: boolean;
}

export interface SongRequest {
  email: string;
  hymnalName: string;
  songNumber: number;
  timestamp: number;
  issue: HymnIssue;
}

export interface Issue {
  hymnalName?: string;
  songNumber?: number;
  timestamp: number;
  description: any;
  status?: string;
}

export enum HymnIssue {
  Missing = 'Missing', // Not yet added to app
  Music = 'Music',     // Music copyright (trumps text copyright)
  Text = 'Text',       // Text copyright
}

export enum SongListType {
  WorshipService = 'worship-service',
  HymnOfTheMonth = 'hymn-of-the-month',
  Event = 'event',
  StaticList = 'static-list',
}

export interface SongList {
  date: DateString;
  type: SongListType;
  name?: string;
  time?: string;
  songs: SongListEntry[];
}

export interface SongListEntry {
  hymnal: string;
  number: number;
  title?: string; // perhaps this should be required?
  psalm?: string;
}

export interface HymnalsManifest {
  [hymnalName: string]: HymnalsManifestEntry;
}

export interface HymnalsManifestEntry extends Hashed {
  id: number;
  publisher: string;
  isInternal?: boolean;
  isChurchSpecific?: boolean;
}

export interface Hashed {
  hash: string;
}

export interface HymnalManifest {
  [songNumber: string]: SongManifestEntry;
}

export enum SongFeature {
  Vocals = "Vocals",
}

export interface SongManifestEntry {
  title: string,
  hash: string;
  psalm?: string;
  issue?: HymnIssue;
  features?: SongFeature[];
}

export function computeAggregateHash(hashes: Hashed[]): string {
  let content = hashes.reduce((acc, {hash}) => (acc + hash), '');
  return md5.md5(content).substring(0, hashLength);

  // alternatively, we could use the built-in browser sha-1 encoder (no built-in md5)
  // const encoder = new TextEncoder();
  // const data = encoder.encode(content);
  // const hashBuffer = await crypto.subtle.digest('SHA-1', data);
  // const hashArray = Array.from(new Uint8Array(hashBuffer));
  // const hashHex = hashArray
  //   .map((b) => b.toString(16).padStart(2, "0"))
  //   .join(""); // convert bytes to hex string
  // return hashHex.substring(0, hashLength);
}

export interface AppHashes {
  appVersion: string;
  hymnalsHash: string;
}

export const hashLength = 8

// for test purposes so the server doesn't log an error during client tests
export const InvalidTestCredentials = `Google Y0NTNkYzlkYzNkZDkzM2E0MWVhNTdkYTNlZjI0MmIwZjciLCJ0eXAiOiJKV1QifQ.iOjE2NjAyNDIzNjUsImF1ZCI6Ijk3MjQ4MDczMDU1MC0yOXM0MzFpYzY2aGljNmxkcGlkcDY2c2NnMW81b3A3aC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwMzg4NDk4OTEwMzEyMTg1MDM3OCIsImVtYWlsIjoidGVzdG1haWxjcmVzY2VuZG9AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6Ijk3MjQ4MDczMDU1MC0yOXM0MzFpYzY2aGljNmxkcGlkcDY2c2NnMW81b3A3aC5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsIm5hbWUiOiJDb2RlIFRlc3RlciIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BSXRidm1tQnJiNnFaeTRpUXhMYWxwVmd0Ym8zbHhwN3JyME9mQ1psc25oXz1zOTYtYyIsImdpdmVuX25hbWUiOiJDb2RlIiwiZmFtaWx5X25hbWUiOiJUZXN0ZXIiLCJpYXQiOjE2NjAyNDI2NjUsImV4cCI6MTY2MDI0NjI2NSwianRpIjoiMjNlNzg4OTljMGVhYTJjYjJjOTg0NTQwMGNkNDYzZjYxMTZlMjQ2YiJ9.cWgj6TIwOpciUtgkw7JAqpVkPDXImyBANIShJg_uTbfl3eNX-o5nQ18n8nv5UTNnZCqyAOQYDWP3tr_j6bQs7FTUOzfopJL4WxbYKIQvpbtQhQaMtxwrqgmvW76bYNjGoAeED8eh-CDvsk4xpNGeErfjIvDaqnRGMJPRmPjQEjygKvch5sL1U4sPe5K7hrhHqZdZQy-h-VcCu2HuDzs1FW8Pdg--g65YiKMeObWu-wWq45nLzgijiwTRXTqcNwgNP_7GtRinxg5mhdhsskWry3THd3xBuB2BfKKHelr5coQ`

export const AppBundleId = `com.crescendosw.ensemble`;

export enum AppConfig {
  EnableAppleServerWebhookLogging = 'enable-apple-server-webhook-logging',
  SuppressAppUpdateButton = 'suppress-app-update-button', // TODO(hewitt): Remove when app store approves 3.5
  AllowEditableHousehold = 'allow-editable-household', // TODO(halvorson): Remove when household management is shipped
  EnableOnboarding = 'enable-onboarding',
}

export enum DiscoverySource {
  Church = 'church',
  School = 'school',
  Friend = 'friend',
  Self = 'self',
}

export enum ConfidenceLevel {
  Unable = 'unable',
  Sometimes = 'sometimes',
  Regularly = 'regularly',
  Choir = 'choir',
  Leader = 'leader',
}

export enum ExpectedUsage {
  PostSongLists = 'post-song-lists',
  Choir = 'choir',
  SmallGroup = 'small-group',
  Home = 'home',
  Harmony = 'harmony',
}

export enum HouseholdSchedule {
  Morning = 'morning',
  Lunch = 'lunch',
  Dinner = 'dinner',
  Bedtime = 'bedtime',
}
