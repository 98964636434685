import React, {useCallback} from 'react';
import {Frame} from './frame';
import {Content, Message} from './common';
import {Button} from './button';
import {navigateTo} from '../../util/use_location';
import styled from 'styled-components/macro';
import {useHousehold} from '../../data/use_household';
import {OnboardingPages} from '../../common/pages';

export const SurveyHouseholdNotifications = () => {
  const {household} = useHousehold();
  const onContinue = useCallback(() => {
    navigateTo(household ? OnboardingPages.ShareFriendsFamily : OnboardingPages.HouseholdStart);
  }, [household]);
  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={20}>
            Awesome, we'll help make sure you're two taps away from singing when it's time.
          </Message>
          <Content>
            <NotificationsImage key='friend' src='/images/notifications.png'/>
          </Content>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const NotificationsImage = styled.img`
  width: 90%;
  height: auto;
  padding: 10px 10px 10px 20px;
`;
