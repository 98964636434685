import React, {useCallback} from 'react';
import {BoldMessage, Message} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {useCache} from '../../data/use_cache';
import {CacheKey} from '../../data/client_cache';
import {navigateTo} from '../../util/use_location';
import {OnboardingPages} from '../../common/pages';
import {withSearchParams} from '../../common/util';

export const HouseholdNotFound = () => {
  const [householdEmail] = useCache(CacheKey.OnboardingHouseholdEmail);
  const [lastName] = useCache(CacheKey.OnboardingLastName);
  const onChangeEmail = useCallback(() => {
    navigateTo(withSearchParams(OnboardingPages.HouseholdFind));
  }, []);
  const onNewHousehold = useCallback(() => {
    navigateTo(withSearchParams(lastName
      ? OnboardingPages.HouseholdCreate
      : OnboardingPages.HouseholdLastName
    ));
  }, [lastName]);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={26}>
          We could not find a household with email <BoldMessage>{householdEmail}</BoldMessage>.<br/><br/>
          Would you like try another email?
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onChangeEmail}>TRY AGAIN</Button>
          <Button onClick={onNewHousehold}>NEW HOUSEHOLD</Button>
        </>
      )}
      onEnterKeypress={onChangeEmail}
    />
  );
};
