import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as BlankProfilePicture} from "../assets/blank-profile-picture.svg";
import {CacheKey, Guest} from "../data/client_cache";
import {useCache} from "../data/use_cache";
import {AppleLogin, showGoogleButton} from "./apple_login";
import GoogleLogin, {getGooglePicture, isGoogleCredentials} from "./google_login";
import './user_info.css'
import {navigateTo} from "../util/use_location";
import {migrateUserChurchToHouseholdChurch} from '../util/billing';
import styled from 'styled-components/macro';

interface Props {
  visible: boolean;
  prompt?: string;
}

export function UserInfo(props: Props) {
  const [user, setUser] = useCache(CacheKey.User);
  const [credentials] = useCache(CacheKey.Credentials);
  const [picture, setPicture] = useState();

  useEffect(() => {
    // Apple does not provide a photo
    setPicture(credentials && isGoogleCredentials(credentials) ? getGooglePicture(credentials) as any : undefined);
  }, [credentials]);

  useEffect(() => {
    void migrateUserChurchToHouseholdChurch();
  }, [user]);

  const handleSignOut = useCallback(() => {
    setUser(Guest);
    navigateTo('/');
  }, [setUser]);

  const profilePicture =
    picture ?
      (<img src={picture} alt='Avatar' id="profilePicture"/>) :
      (<BlankProfilePicture id="profilePicture"/>);

  const userName = props.prompt ?? (
    user && user !== Guest && user.name ? user.name : "Not Signed In"
  );

  const buttons =
    credentials ?
      (
        <div id="lowerDiv">
          <button onClick={handleSignOut} id="signOutButton">Sign Out</button>
        </div>
      ) :
      (
        <div id="lowerDiv">
          <div hidden={!showGoogleButton()}>
            <GoogleLogin hidden={!props.visible} location={"settings"}/>
          </div>
          <br />
          {
            // occasionally on localhost, AppleID is not defined
            // (see https://github.com/patelmayankce/react-apple-login/issues/41)
            // seems to only happen on when the AppleLogin is rendered but not actually visible
            props.visible ? (<AppleLogin location={"settings"}/>) : (<div>Hiding Apple Login</div>)
          }
        </div>
      );

  return (
    <div id="loginDiv">
      <div id="loginInfo">
        {profilePicture}
        <UserName>{userName}</UserName>
      </div>
      {buttons}
    </div>
  )
}

const UserName = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  padding-top: 10px;
  white-space: pre-wrap;
`;