import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message, Title} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {useHousehold} from '../../data/use_household';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../common/pages';

export const SubscribeStart = () => {
  const {household} = useHousehold();
  const onContinue = useCallback(() => {
    if (household) {
      navigateTo(OnboardingPages.SubscribePayment);
    } else {
      navigateTo(OnboardingPages.SubscribeFindHousehold);
    }
  }, [household]);
  return (
    <Frame
      mainContent={(
        <>
          <Partner key='partner'>Partner with us!</Partner>
          <Message $widthInChars={24}>
            By subscribing, you gain access to our full hymnal library
            and join us as we push good music into all the corners of life.
          </Message>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const Partner = styled(Title)`
  font-size: 2.1em;
  padding-bottom: 2em;
`;
