import React, {useEffect, useState} from 'react';
import {getHymnFromSongListEntry, HymnalWithHymns, HymnsList} from './hymns_list';
import '../shared.css';
import {
  specialCaseSchools,
  synchronizeWeeklySongLists,
  useChurch
} from '../data/use_church';
import {useCache} from '../data/use_cache';
import {CacheKey} from '../data/client_cache';
import {navigateTo} from "../util/use_location";
import {
  Church,
  OrganizationType,
  SongList,
  SongListEntry,
  SongListType
} from '../common/model';
import {dateFromString} from '../common/date_string';
import {defaultSongList} from '../data/default_song_lists';
import {useHousehold} from '../data/use_household';
import {ensureUnreachable} from '../common/util';
import {musicLeaderMailToHRef} from './onboarding/common';

const todayTitle = 'Today';
const thisSundayTitle = 'This Sunday';
const lastSundayTitle = 'Last Sunday';
const nextSundayTitle = 'Next Sunday';

function getHymnalsFromSongLists(songsForWeek?: SongList[], church?: Church): HymnalWithHymns[] {
  if (!church) {
    return defaultSongList;
  }
  if (songsForWeek === undefined) {
    return [];
  }
  const hymnals: HymnalWithHymns[] = songsForWeek
    .filter(songList => songList.type !== SongListType.StaticList || songsForWeek.length === 1)
    .map((songList) => ({
      id: 0,
      name: getSongListName(songList, songsForWeek.length),
      hymns: songList.songs.map(song => getHymnFromSongListEntry(song)), // b/c title is optional
    }));
  if (
    !songsForWeek.map(({date}) => date).includes(thisSundayDate()) &&
    church.type === OrganizationType.Church &&
    !specialCaseSchools.includes(church.name) &&
    // hide empty "This Sunday" for single static song list
    (songsForWeek.length > 1 || hymnals.length === 0 || hymnals[0].name !== '')
  ) {
    // keep hymn of the month first
    const containsHymnOfTheMonth = songsForWeek.length > 0 && songsForWeek[0].type === SongListType.HymnOfTheMonth;
    const start = containsHymnOfTheMonth ? 1 : 0;
    hymnals.splice(start, 0, {
      id: 0,
      name: getSundayTitle(thisSundayDate()),
      hymns: [],
      message: <div>Your music leader has not yet added any songs for this week.</div>,
    })
  }
  return hymnals;
}

function getSongListName(songList: SongList, songListCount: number) {
  const {name, type, date: dateStr, time, songs} = songList;
  switch (type) {
    case SongListType.HymnOfTheMonth:
      return getHymnOfTheMonthTitle(songs);
    case SongListType.WorshipService:
      return getSundayTitle(dateStr);
    case SongListType.Event:
      const date = dateFromString(dateStr);
      const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
      const shortTime = getTimeAsShortString(time);
      const timePart = shortTime ? ` @ ${shortTime}` : '';
      return `${name}${datePart}${timePart}`;
    case SongListType.StaticList:
      // If there is only one static song list, it gets an empty title (flat list of songs)
      return songListCount === 1 ? '' : 'Songs';
    default:
      ensureUnreachable(type);
  }
}

function getHymnOfTheMonthTitle(songs: SongListEntry[]) {
  const isPsalm = songs.length > 0 && (songs[0].psalm || songs[0].title?.toLowerCase().includes('psalm'));
  const prefix = isPsalm ? 'Psalm' : 'Hymn';
  return `${prefix} of the Month`;
}

function getSundayTitle(date: string) {
  const today = getDateAsLongString(new Date());
  switch (date) {
    case today:
      return todayTitle;
    case thisSundayDate():
      return thisSundayTitle;
    case lastSundayDate():
      return lastSundayTitle;
    case nextSundayDate():
      return nextSundayTitle;
    default:
      return date;
  }
}

function nextSundayDate() {
  const today = new Date();
  if (today.getDay() !== 0) {
    return 'no match';
  }
  const result = today;
  result.setDate(today.getDate() + 7);
  return getDateAsLongString(result);
}

function thisSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0));
  return getDateAsLongString(result);
}

function lastSundayDate() {
  const today = new Date();
  const result = today;
  result.setDate(today.getDate() + (today.getDay() > 0 ? 7 - today.getDay() : 0) - 7);
  return getDateAsLongString(result);
}

function getDateAsLongString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}

function getTimeAsShortString(time?: string): string | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/T(?<hour>\d\d):(?<minute>\d\d):(?<second>\d\d)/);
  if (!match || !match.groups) {
    return;
  }
  const {hour: hourString, minute} = match.groups;
  const militaryHour = Number(hourString);
  const hour = militaryHour === 0 ? 12 : (militaryHour > 12 ? militaryHour - 12 : militaryHour);
  const ampm = militaryHour < 12 || militaryHour === 24 ? 'am' : 'pm';
  return `${hour}:${minute}${ampm}`;
}

export const ThisWeekPage = () => {
  const {church} = useChurch();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const [songsForWeek] = useCache(CacheKey.WeeklySongList);
  const [hymnals, setHymnals] = useState(() =>
    getHymnalsFromSongLists(songsForWeek, church)
  );

  useEffect(() => {
    void synchronizeWeeklySongLists();
  }, []);

  useEffect(() => {
    setHymnals(getHymnalsFromSongLists(songsForWeek, church));
  }, [songsForWeek, church]);

  const askYourMusicLeader =
    <a className="inlineLink" href={musicLeaderMailToHRef}>
      ask your music leader
    </a>;

  let message: JSX.Element | undefined;
  // TODO(hewitt): remove songsForWeek check 8/2024 - should be householdToken only (see above as well)
  if (!householdToken && !songsForWeek) {
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span className="inlineLink" onClick={() => navigateTo('/settings')}>sign in</span> if your church already participates.
      </div>;
  } else if (!church) {
    message = <div>To use this week, select a church in household <span className="inlineLink" onClick={() => navigateTo('/settings')}>settings</span> or  {askYourMusicLeader} to add your church to the app. </div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0) {
    message = <div>Your music director has not yet added songs for this Sunday.</div>;
  }
  return (
    <HymnsList
      title={church?.name ?? 'Songs By Week'}
      weeklySongGroups={hymnals}
      expandForThisWeek={true}
      message={message}
    />
  );
};
