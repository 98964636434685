import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';

export const Start = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.Questions);
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={16}>Hi! Thanks for checking us out.</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
