import React, {useCallback} from 'react';
import {Hymn} from '../../sequencer';
import {generateHymnUrl} from '../../util/path';
import {Device, getDevice} from '../../util/platform';
import {navigateTo} from '../../util/use_location';
import {Frame} from './frame';
import {defaultSongList} from '../../data/default_song_lists';
import {Content, Message, Title} from './common';
import {Button} from './button';
import styled from 'styled-components/macro';
import {ReactComponent as PlayButtonOutline} from '../../assets/play-button-outline.svg';
import {getOnboardingPath, OnboardingPages} from '../../common/pages';
import {AppStoreUrl, GooglePlayUrl, isInsideMobileApp} from '../../util/billing';


// navigate to app store in mobile browsers
function beginOnboarding(page: OnboardingPages) {
  const appStoreUrl = getAppStoreUrl();
  if (appStoreUrl) {
    window.location.href = appStoreUrl;
    return;
  }
  navigateTo(getOnboardingPath(page));
}

function getAppStoreUrl(): string | undefined {
  if (!isInsideMobileApp() && window.location.port !== '3000') {
    if (getDevice() === Device.iOS) {
      return AppStoreUrl;
    } else if (getDevice() === Device.Android && !window.location.hostname.startsWith('test')) {
      return GooglePlayUrl;
    }
  }
}

export const Demo = () => {
  const onSongClick = useCallback((hymn: Hymn) => {
    return () => {
      navigateTo(generateHymnUrl(hymn));
    };
  }, []);

  const onGetStarted = useCallback(() => {
    beginOnboarding(OnboardingPages.Start);
  }, []);

  const onHaveHousehold = useCallback(() => {
    beginOnboarding(OnboardingPages.HouseholdFind);
  }, []);

  return (
    <Frame
      mainContent={(
        <>
          <Title key='title'>Sing Your Part</Title>
          <Message key='message' $widthInChars={16}>Here are some sample songs for you to sing:</Message>
          <Content key='content'>
            {defaultSongList[0].hymns.map(hymn =>
              <SongButton key={hymn.title} onClick={onSongClick(hymn)}>
                <PlayButtonOutline style={{color: 'var(--color-text)', height: '2.5em', width: '2.5em'}}/>
                <SongTitle>{hymn.title}</SongTitle>
              </SongButton>
            )}
          </Content>
        </>
      )}
      footerContent={(
        <>
          <Button key='getStarted' onClick={onGetStarted}>GET STARTED</Button>
          {
            !getAppStoreUrl() &&
            <Button key='haveHousehold' onClick={onHaveHousehold}>I ALREADY HAVE A HOUSEHOLD</Button>
          }
        </>
      )}
      suppressBackButton={true}
      onEnterKeypress={onHaveHousehold}
    />
  );
}

const SongButton = styled.button`
  display: flex;
  gap: 0.7em;
  align-items: center;
  color: var(--color-text);
  background-color: var(--color-background);
  border: none;
  cursor: pointer;
`;

const SongTitle = styled.div`
  font-family: Jost-SemiBold, sans-serif;
  font-size: 1.2em;
  text-align: left;
  width: 15ch;
`;
