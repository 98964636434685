import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {BoldMessage, Message} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {OnboardingPages} from '../../common/pages';

export const Questions = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.SurveyDiscovery);
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={20}>Just <BoldMessage>a few quick questions</BoldMessage> before we get started!</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
