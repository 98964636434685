import styled from 'styled-components/macro';
import {CacheKey, cacheRemove} from '../../data/client_cache';

const musicLeaderEmailSubject = encodeURIComponent('Joining Sing Your Part');
const musicLeaderEmailBody = encodeURIComponent(
  'Dear Leader,\n' +
  '\n' +
  'I would like you to check out this app that makes the church\'s music more accessible to everyone!  Check out the app here: https://singyourpart.org\n' +
  '\n' +
  'Click the link below to set a 20 minute phone call with their team located in Boise and they\'ll go over what it would look like for the church. Thank you!\n' +
  '\n' +
  'https://calendar.app.google/cn23rz2diHASqKon9\n' +
  '\n' +
  'Blessings,\n' +
  '\n'
);
export const musicLeaderMailToHRef = `mailto:?subject=${musicLeaderEmailSubject}&to=type-email-here&cc=support@crescendosw.com&body=${musicLeaderEmailBody}`;

export const Title = styled.div`
  // TODO(hewitt): Use Bold instead of SemiBold 
  font-family: Jost-SemiBold, sans-serif;
  font-size: 2.7em;
  color: var(--color-text-button-onboarding);
  padding-bottom: 0.5em;
  text-wrap: nowrap;
`;

export const Message = styled.div<{$widthInChars: number}>`
  font-size: 1.3em;
  width: ${props => props.$widthInChars}ch;
  padding-bottom: 1.5em;
`;

export const BoldMessage = styled.span`
  font-family: Jost-SemiBold, sans-serif;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.3em;
  overflow: scroll;
`;

export const ButtonContent = styled(Content)`
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
`;

export const TextInput = styled.input`
  font-family: Jost-SemiBold, sans-serif;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid var(--color-text);
  text-align: center;
  color: var(--color-text);
  background-color: transparent;
  width: 20ch;
`;

export function clearOnboardingCache() {
  cacheRemove(CacheKey.OnboardingHouseholdEmail);
  cacheRemove(CacheKey.OnboardingLastName);
  cacheRemove(CacheKey.OnboardingChurch);
}

export const SubscribeContextQueryString = 'subscribe';
export const EditAllHouseholdInfoQueryString = 'edit-all';

export function subscribing(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(SubscribeContextQueryString);
}

export function editAllHouseholdFields(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.has(EditAllHouseholdInfoQueryString);
}
