import * as server_api from '../common/server_api';
import {cacheGet, CacheKey, cacheSet} from './client_cache';
import {AppConfig} from '../common/model';
import {useCache} from './use_cache';

const ConfigSyncIntervalMinutes = 5;

export async function synchronizeAppConfigWithServer() {
  // ignore network errors (e.g. when offline)
  try {
    const serverAppConfig = await server_api.getAppConfig();
    const appConfig: {[key in AppConfig]?: boolean} = {};
    for (const key of Object.values(AppConfig)) {
      appConfig[key] = key in serverAppConfig ? processAppConfigValue(serverAppConfig[key]) : false;
    }
    cacheSet(CacheKey.AppConfig, appConfig as {[key in AppConfig]: boolean});
  } catch { }
  setTimeout(synchronizeAppConfigWithServer, ConfigSyncIntervalMinutes * 60 * 1000);
}

// converts a vanilla app config object into a boolean
function processAppConfigValue(value?: any): boolean {
  if (value === undefined || value === null) {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  }
  if ('internal' in value) {
    return Boolean(cacheGet(CacheKey.IsInternalUser));
  }
  // checking the environment is nonsense because we would need to manually set the value in each environment
  // if ('environment' in value) {
  //   const {environment}: {environment: Environment} = value;
  //   if (environment === Environment.Production && getEnvironment() === Environment.Production) {
  //     return true;
  //   }
  //   if (environment === Environment.Test && getEnvironment() !== Environment.Production) {
  //     return true;
  //   }
  //   return true;
  // }
  return false;
}

export function useAppConfig(config: AppConfig): boolean {
  const [appConfig] = useCache(CacheKey.AppConfig);
  return appConfig[config];
}
