import {useCache} from '../../data/use_cache';
import {CacheKey, cacheRemove} from '../../data/client_cache';
import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {BoldMessage, clearOnboardingCache, Message, subscribing} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {alert} from '../../util/confirm';
import {synchronizeChurchWithServer, useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import {HouseholdStatus} from '../../common/model';
import {withSearchParams} from '../../common/util';

export const HouseholdJoin = () => {
  const [householdEmail] = useCache(CacheKey.OnboardingHouseholdEmail);
  const [household] = useCache(CacheKey.Household);
  const [onboardingChurch] = useCache(CacheKey.OnboardingChurch);
  const {setChurch} = useChurch();
  const onJoinHousehold = useCallback(async () => {
    if (!household) {
      await alert({confirmation: <span>An error occurred joining household</span>});
      navigateTo(OnboardingPages.HouseholdEmail);
      return;
    }
    if (onboardingChurch) {
      setChurch(onboardingChurch);
    } else {
      await synchronizeChurchWithServer({force: true});
    }
    clearOnboardingCache();
    let page: OnboardingPages;
    if (subscribing()) {
      if (household.status === HouseholdStatus.Subscribed) {
        page = OnboardingPages.SubscribeThankYou;
      } else {
        page = OnboardingPages.SubscribePayment;
      }
    } else {
      page = OnboardingPages.ShareFriendsFamily;
    }
    navigateTo(page);
  }, [household, onboardingChurch, setChurch]);
  const onEditEmail = useCallback(() => {
    cacheRemove(CacheKey.Household);
    navigateTo(withSearchParams(OnboardingPages.HouseholdEmail));
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={26}>
          A household already exists with email <BoldMessage>{householdEmail}</BoldMessage>.<br/><br/>
          {subscribing() ? 'Subscribe this household' : 'Would you like to join it'}?
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onJoinHousehold}>YES</Button>
          <Button onClick={onEditEmail}>NO</Button>
        </>
      )}
      onEnterKeypress={onJoinHousehold}
    />
  );
};
