import React, {useCallback} from 'react';
import {Frame} from './frame';
import {ButtonContent, Message} from './common';
import {Button, LeftAlignedButton} from './button';
import {useCache} from '../../data/use_cache';
import {CacheKey} from '../../data/client_cache';
import styled from 'styled-components/macro';
import {navigateTo} from '../../util/use_location';
import {OnboardingPages} from '../../common/pages';

interface Option {
  prompt: string;
  timeInvestment: number;
}

export const SurveyTimeInvestment = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.HouseholdStart);
  }, []);

  const options: Option[] = [
    {prompt: '5 min/day', timeInvestment: 5},
    {prompt: '10 min/day', timeInvestment: 10},
    {prompt: '15 min/day', timeInvestment: 15},
  ];

  const [selectedTimeInvestment, setSelectedTimeInvestment] = useCache(CacheKey.OnboardingTimeInvestment);

  const onChooseOption = useCallback((timeInvestment: number) => {
    return () => {
      setSelectedTimeInvestment(timeInvestment);
    };
  }, [setSelectedTimeInvestment]);

  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={16}>How much time can you invest?</Message>
          <ButtonContent>
            {
              options.map((option) => (
                <LeftAlignedButton
                  key={option.timeInvestment}
                  onClick={onChooseOption(option.timeInvestment)}
                  notPrimary={true}
                  selected={option.timeInvestment === selectedTimeInvestment}
                >
                  <OptionWrapper>
                    {option.prompt}
                  </OptionWrapper>
                </LeftAlignedButton>
              ))
            }
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
