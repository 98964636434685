import {useCache} from '../../data/use_cache';
import {CacheKey, cacheSet} from '../../data/client_cache';
import React, {useCallback} from 'react';
import {alert} from '../../util/confirm';
import {navigateTo} from '../../util/use_location';
import {
  BoldMessage,
  clearOnboardingCache,
  EditAllHouseholdInfoQueryString,
  Message,
  SubscribeContextQueryString
} from './common';
import {Frame} from './frame';
import {Button} from './button';
import * as server_api from '../../common/server_api';
import {Household} from '../../common/model';
import {synchronizeChurchWithServer, useChurch} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import {withSearchParams} from '../../common/util';

export const HouseholdCreate = () => {
  const [familyLastName] = useCache(CacheKey.OnboardingLastName);
  const [householdEmail] = useCache(CacheKey.OnboardingHouseholdEmail);
  const [onboardingChurch] = useCache(CacheKey.OnboardingChurch);
  const {setChurch} = useChurch();
  const onCreateHousehold = useCallback(async () => {
    if (!familyLastName || !householdEmail) {
      await alert({confirmation: <span>Some information is missing</span>});
      navigateTo(withSearchParams(OnboardingPages.HouseholdLastName));
      return;
    }
    let household: Household;
    try {
      household = await server_api.createHousehold({householdEmail, familyLastName});
    } catch (err) {
      await alert({confirmation: <span>An error occurred creating household</span>});
      navigateTo(withSearchParams(OnboardingPages.HouseholdEmail));
      return;
    }
    cacheSet(CacheKey.Household, household);
    if (onboardingChurch) {
      setChurch(onboardingChurch);
    } else {
      await synchronizeChurchWithServer({force: true});
    }
    clearOnboardingCache();
    const searchParams = new URLSearchParams(window.location.search);
    navigateTo(searchParams.has(SubscribeContextQueryString)
      ? OnboardingPages.SubscribePayment
      : OnboardingPages.ShareFriendsFamily
    );
  }, [familyLastName, householdEmail, onboardingChurch, setChurch]);
  const onEditInfo = useCallback(() => {
    navigateTo(withSearchParams(OnboardingPages.HouseholdLastName, EditAllHouseholdInfoQueryString));
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={24}>
          Would like to create a new <BoldMessage>{familyLastName}</BoldMessage> household with email <BoldMessage>{householdEmail}</BoldMessage>?
        </Message>
      )}
      footerContent={(
        <>
          <Button onClick={onCreateHousehold}>CREATE HOUSEHOLD</Button>
          <Button onClick={onEditInfo}>EDIT INFORMATION</Button>
        </>
      )}
      onEnterKeypress={onCreateHousehold}
    />
  );
};
