export enum OnboardingPages {
  AllowNotifications = 'allow-notifications',
  Awesome = 'awesome',
  ChurchChoice = 'church-choice',
  ChurchWelcome = 'church-welcome',
  Demo = 'demo',
  Download = 'download',
  HouseholdStart = 'household-start',
  HouseholdLastName = 'household-last-name',
  HouseholdEmail = 'household-email',
  HouseholdNotFound = 'household-not-found',
  HouseholdJoin = 'household-join',
  HouseholdCreate = 'household-create',
  HouseholdFind = 'household-find',
  HouseholdWelcome = 'household-welcome',
  SubscribeStart = 'subscribe-start',
  SubscribeFindHousehold = 'subscribe-find-household',
  SubscribePayment = 'subscribe-payment',
  SubscribeThankYou = 'subscribe-thank-you',
  SurveyDiscovery = 'survey-discovery',
  SurveyHouseholdSchedule = 'survey-household-schedule',
  SurveyHouseholdNotifications = 'survey-household-notifications',
  SurveyConfidenceLevel = 'survey-confidence-level',
  SurveyExpectedUsage = 'survey-expected-usage',
  SurveyTimeInvestment = 'survey-time-investment',
  Questions = 'questions',
  ShareFriendsFamily = 'share-friends-family',
  ShareMusicLeader = 'share-music-leader',
  ShareMusicLeaderInstructions = 'share-music-leader-instructions',
  Start = 'start',
}

export enum Pages {
  Demo = 'demo',
  Favorites = 'favorites',
  Help = 'help',
  Library = 'library',
  Settings = 'settings',
  ThisWeek = 'this-week',
  AdminInvite = 'admin-invite',
  ChurchHouseholdInvite = 'church-household-invite',
  HouseholdCreation = 'household',
  Onboarding = 'onboarding',
}

export function getOnboardingPath(page?: OnboardingPages) {
  return !page ? `/${Pages.Onboarding}` : `/${Pages.Onboarding}/${page}`;
}
