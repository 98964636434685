import autoBind from 'auto-bind';
import {MidiFilePlayer} from './midi_file_player';
import {generateFileName, generatePathFor} from './util/path';
import {addListener, cacheGet, CacheKey, defaultVerseCount} from './data/client_cache';
import {HymnIssue, SongFeature} from "./common/model";
import {MidiFilePlayerWebAudio} from "./midi_file_player_web_audio";
import {ensureExists} from "./common/util";
import {VocalsPlayer} from "./vocals_player";
import {hymnalsPath} from "./shared";
import {VocalsPlayerSimple} from "./vocals_player_simple";
import {Stopwatch} from './util/stopwatch';

export interface Hymn {
  hymnal: string;
  number: number;
  title: string;
  psalm?: string;
  issue?: HymnIssue;
  verseCount?: number;
  author?: string;
  composer?: string;
  harmony?: string;
  copyright?: string;
  key?: string;
  features?: SongFeature[];
  vocals?: string;
  vocalPartCount?: string;
}

type AnyPlayer = MidiFilePlayer | MidiFilePlayerWebAudio | VocalsPlayer | VocalsPlayerSimple;

export class Sequencer {
  songIntro: boolean | undefined;
  songIntroPlayed: boolean = false;
  private _verseIndex = 0; // todo: state change event to UI?
  private _songIndex = 0; // use setSongIndex to update!
  private _modulationHalfSteps = 0;
  private _playing = false;
  private _stopwatch: Stopwatch;
  private _stopwatchSubscription: number | undefined;
  private _hymns: Hymn[];
  private _midiFilePlayer: AnyPlayer;
  private _onHymnChange: (hymn: Hymn) => void;
  private _onReadyToPlay: (tempo: number) => void;
  private _onNetworkError: (response?: Response) => void;
  private _setPlaying: (playing: boolean) => void;
  private  _onVerseChange: (verse: string) => void;
  private get _hymn() { return this._hymns[this._songIndex]; }
  private _vocalsEnabled: boolean

  constructor(hymns: Hymn[], {
    onHymnChange,
    onNetworkError,
    onReadyToPlay,
    onVerseChange,
    setPlaying,
    stopwatch,
  }: {
    onHymnChange: (hymn: Hymn) => void;
    onReadyToPlay: (tempo: number) => void;
    onNetworkError: (response?: Response) => void;
    setPlaying: (playing: boolean) => void;
    onVerseChange: (verse: string) => void;
    stopwatch: Stopwatch;
  }) {
    autoBind(this);
    this.songIntro = cacheGet(CacheKey.SongIntro);
    this._hymns = hymns;
    this._onHymnChange = onHymnChange;
    this._setPlaying = setPlaying;
    this._onReadyToPlay = onReadyToPlay;
    this._onNetworkError = onNetworkError;
    this._onVerseChange = onVerseChange;
    this._vocalsEnabled = false;
    this._midiFilePlayer = this._createMidiFilePlayer();
    this._refreshKeyModulation();
    this._stopwatch = stopwatch;
    addListener(CacheKey.KeyModulation, this._refreshKeyModulation);
  }

  play() {
    if (this.songIntro && !this.songIntroPlayed) {
      this._midiFilePlayer.setLocationPercentage(50)
    }
    this._midiFilePlayer.play();

    this._playing = true;
    this._setPlaying(this._playing);
    if (this._stopwatchSubscription) {
      console.log('Warning: Stopwatch should not be running prior to playing song (iOS browser bug?)');
      this._stopwatch.unsubscribe(this._stopwatchSubscription);
    }
    this._stopwatchSubscription = this._stopwatch.subscribe();
  }

  pause() {
    this._pause({userAction: true});
  }

  setVocalsEnabled(value: boolean) {
    this._vocalsEnabled = value;
    this._midiFilePlayer = this._createMidiFilePlayer();
    this._refreshKeyModulation();
  }

  private _pause({userAction}: {userAction?: boolean} = {}) {
    this._midiFilePlayer.pause();
    this._playing = false;
    this._setPlaying(this._playing);
    if (this._stopwatchSubscription) {
      this._stopwatch.unsubscribe(this._stopwatchSubscription);
      this._stopwatchSubscription = undefined;
    }
  }

  private get _supportsLooping() {
    return this._midiFilePlayer instanceof MidiFilePlayer;
  }

  nextSong() {
    // just reuse the current midi file player if there is only one song
    this.songIntroPlayed = false;
    const repeat = cacheGet(CacheKey.Repeat);
    if (this._hymns.length === 1 && repeat && this._playing) {
      setTimeout(() => this.play(), 0)
      return;
    }
    const playing = this._playing;
    this._setSongIndex((this._songIndex + 1) % this._hymns.length);
    if (playing && (repeat || this._songIndex !== 0)) {
      setTimeout(this.play, 200);
    }
  }

  previousSong() {
    const playing = this._playing;
    const {length} = this._hymns;
    const repeat = cacheGet(CacheKey.Repeat);
    this._setSongIndex(((this._songIndex - 1) + length) % length);
    if (playing && (repeat || this._songIndex !== 0)) {
      setTimeout(this.play, 200);
    }
  }

  get initialTime() { return this._midiFilePlayer.initialTime; }

  reset() {
    this.pause();
    this.songIntroPlayed = false;

    if(cacheGet(CacheKey.SongIntro)) {
      this.songIntro = true;
      this.changeVerse('0', true)
    } else {
      this.songIntro = false;
      this.changeVerse('1', false)
    }

    this._midiFilePlayer.restart()
  }

  displayTime() {
    this._midiFilePlayer.displayTime();
  }

  setNewTime() {
    this._midiFilePlayer.setNewTime();
  }

  onTempoOrVolumeChange() {
    this._midiFilePlayer?.onTempoOrVolumeChange();
  }

  destroy() {
    this._pause()
    this._midiFilePlayer.destroy();
  }

  private _setSongIndex(index: number) {
    this._pause();
    if (this._songIndex !== index) {
      this._playing = false;
      this._songIndex = index;
      this._verseIndex = 0;
      this._midiFilePlayer = this._createMidiFilePlayer();
      this._refreshKeyModulation();
    }
  }

  private _songReady(tempo: number) {
    this._onReadyToPlay(tempo);
    this._onVerseChange(this._verseIndex.toString())
    if (this._playing) {
      setTimeout(this.play, 200)
    }
  }

  private _handleNetworkError(response?: Response) {
    this._onNetworkError(response);
  }

  private _createMidiFilePlayer() {
    if (this._midiFilePlayer) {
      this._pause();
      this.destroy();
    }
    let midiFilePlayer: AnyPlayer;
    if (this._vocalsEnabled && this._hymn.vocals) {
      midiFilePlayer = new VocalsPlayer(
        this._hymn.hymnal,
        this._hymn.vocals,
        this._songReady,
        this._handleNetworkError,
        this._endOfVerse,
      );
      // use VocalsPlayerSimple outside of the iOS app
      // midiFilePlayer = new VocalsPlayerSimple(
      //   this._hymn.hymnal,
      //   songFilename,
      //   this._songReady,
      //   this._handleNetworkError,
      //   this._endOfVerse,
      // );
    } else {
      const songFilename = generateFileName(this._hymn);
      const path = generatePathFor(hymnalsPath, ensureExists(this._hymn.hymnal), songFilename + '.mid');
      let playbackSetting = cacheGet(CacheKey.BackgroundPlayback);
      const MidiFilePlayerClass =
        playbackSetting ?
          MidiFilePlayer :
          MidiFilePlayerWebAudio;
      midiFilePlayer = new MidiFilePlayerClass(
        path,
        this._songReady,
        this._handleNetworkError,
        this._endOfVerse,
        () => this._modulationHalfSteps,
      );
    }
    setTimeout(() => this._onHymnChange(this._hymn));
    return midiFilePlayer;
  }

  private _refreshKeyModulation() {
    this._modulationHalfSteps = 0;
    const transposedSongs = cacheGet(CacheKey.KeyModulation);
    for (const {hymnalName, songNumber, transposeValue} of transposedSongs) {
      if (this._hymn.hymnal === hymnalName && this._hymn.number === songNumber) {
        this._modulationHalfSteps = transposeValue * 2;
        this._midiFilePlayer?.onTempoOrVolumeChange();
      }
    }
  }

  private _endOfVerse() {
    let verseCount = cacheGet(CacheKey.VerseCount)
      || this._hymn.verseCount
      || defaultVerseCount;

    if (this.songIntro) {
      verseCount = verseCount + 1
    }

    if (this.songIntro && !this.songIntroPlayed) {
      this.songIntroPlayed = true
    }
    this._verseIndex = (this._verseIndex + 1) % verseCount;
    this._onVerseChange(this._verseIndex.toString())

    if (this._supportsLooping) {
      // the audio element continues onto the next verse automatically, so just refresh the time slider
      this._midiFilePlayer.updateTimeSlider();
    } else {
      this._midiFilePlayer.restart();
    }

    if (this._verseIndex === 0) {
      this.nextSong()
    } else if (!this._supportsLooping) {
      this._midiFilePlayer.play()
    }
  }

  changeVerse(verse: string, intro: boolean | undefined) {
    if(intro) {
      this._verseIndex = Number(verse)
    } else {
      this._verseIndex = Number(verse) - 1;
    }

    this.songIntroPlayed = !(this._verseIndex === 0 && this.songIntro);

    this._onVerseChange(this._verseIndex.toString())

    setTimeout(() => {
      this._midiFilePlayer.restart()
      if(this._playing) {
        this.play()
      }
    })
  }
}
