import React, {useCallback, useEffect, useRef} from 'react';
import {navigateTo} from '../../util/use_location';
import {Message, TextInput} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {isValidEmail, withSearchParams} from '../../common/util';
import {cacheGet, CacheKey, cacheSet} from '../../data/client_cache';
import {alert} from '../../util/confirm';
import * as server_api from '../../common/server_api';
import {useCache} from '../../data/use_cache';
import {OnboardingPages} from '../../common/pages';

export const HouseholdEmail = () => {
  const emailAddressInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    emailAddressInput.current?.focus();
  }, [emailAddressInput]);

  const [householdEmail, setHouseholdEmail] = useCache(CacheKey.OnboardingHouseholdEmail);
  const onEmailChange = useCallback(() => {
    setHouseholdEmail(emailAddressInput.current?.value);
  }, [setHouseholdEmail]);

  const onContinue = useCallback(async () => {
    const householdEmail = emailAddressInput.current?.value;
    const familyLastName = cacheGet(CacheKey.OnboardingLastName);
    if (!householdEmail || !isValidEmail(householdEmail)) {
      await alert({confirmation: <span>Please enter a valid email address</span>});
      return false;
    }
    if (!familyLastName) {
      await alert({confirmation: <span>'Please enter your last name'</span>});
      navigateTo(withSearchParams(OnboardingPages.HouseholdLastName));
      return;
    }
    let householdToken = await server_api.getHouseholdToken({householdEmail});
    const household = householdToken && await server_api.getHousehold({householdToken});
    if (household) {
      cacheSet(CacheKey.Household, household);
      navigateTo(withSearchParams(OnboardingPages.HouseholdJoin));
    } else {
      navigateTo(withSearchParams(OnboardingPages.HouseholdCreate));
    }
  }, []);

  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={16}>What is a good email for the family?</Message>
          <TextInput
            type='email'
            enterKeyHint='next'
            defaultValue={householdEmail}
            onChange={onEmailChange}
            ref={emailAddressInput}
          />
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!isValidEmail(householdEmail)}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      containsInputElement={true}
    />
  );
}
