import {Hymn} from "../sequencer";
import {Hash, Pathname, Search} from "history";
import {ensureExists} from "../common/util";
import {cacheGet, CacheKey} from '../data/client_cache';
import {getHymnFromUrl} from '../shared';
import {Pages} from '../common/pages';
import {AppConfig} from '../common/model';
import {useAppConfig} from '../data/app_config';

export function generateFileName(hymn: Hymn) {
  let titleAllCaps = ensureExists(hymn.title).toUpperCase();
  const match = hymn.number.toString().match(/^(?<wholeNumber>\d+)(\.(?<decimal>\d+))?/);
  let number: string;
  if (match && match.groups) {
    const {wholeNumber, decimal} = match.groups;
    number = wholeNumber.padStart(3, '0') + (decimal ? `.${decimal.padEnd(2, '0')}` : '');
  } else {
    console.log(`Error parsing hymn number ${hymn.number}`);
    number = hymn.number.toString();
  }

  if(titleAllCaps.match(/^PSALM \d+$/)) {
    return number + ' - ' + hymn.title;
  }
  else {
    if(hymn.psalm) {
      return number + ' - ' + hymn.title + ' - ' + hymn.psalm;
    }
    else {
      return number + ' - ' + hymn.title;
    }
  }
}

export function generatePathFor(base: string, hymnalName: string, fileName: string) {
  // Cantus Christi hymnals were renamed to include parenthesis (e.g. "Cantus Christi (2002)")
  // Some churches still have the old links (e.g. The King's Congregation)
  if (hymnalName.includes('Cantus Christi') && !hymnalName.includes('(')) {
    hymnalName = hymnalName.replace(/(\d+)/, '($1)')
  }
  return `${base}/${encodeMusicURI(hymnalName)}/${encodeMusicURI(fileName)}`;
}

export function encodeMusicURI(uri: string) {
  return encodeURIComponent(uri)
    .replace(/!/g, '%21');
}

export interface Location {
  pathname: Pathname;
  search: Search;
  hash: Hash;
}

export function useCurrentPage(location: Location | undefined)
  : Exclude<Pages, Pages.Demo>
{
  const enableOnboarding = useAppConfig(AppConfig.EnableOnboarding);

  // allow song links to work without a household
  const page = getPageFromLocation(location);
  if ((page === Pages.ThisWeek || page === Pages.Library) && getHymnFromUrl(location?.search)) {
    return page;
  }
  if (!enableOnboarding) {
    // old behavior -> route to This Week page for demo & onboarding
    return !page || page === Pages.Demo || page === Pages.Onboarding ? Pages.ThisWeek : page;
  }
  const household = cacheGet(CacheKey.Household);
  if (!household) {
    return Pages.Onboarding;
  }
  if (page === Pages.Demo) {
    return Pages.ThisWeek;
  }
  return page ?? Pages.ThisWeek
}

export function getPageFromLocation(location: Location | undefined): Pages | undefined {
  if (!location) {
    return;
  }
  const pagePart = location.pathname.match(/\/(?<page>[^/]+)/)?.groups?.page;
  return pagePart && Object.values(Pages).includes(pagePart as Pages) ? pagePart as Pages : undefined;
}

export function getUrlForPage(page: Pages) {
  return '/' + page;
}

export function generateHymnUrl(hymn: Hymn, page?: Pages): string {
  const hymnalName = hymn.hymnal.replace(/ /g, '_');
  const hymnTitle = hymn.title.replace(/ /g, '_');
  const hymnNumber = hymn.number;
  let psalmName;

  if (hymn.psalm) {
    psalmName = hymn.psalm.replace(/ /g, '_');
  } else {
    psalmName = '';
  }

  const url: string =
    getUrlForPage(page ?? Pages.Library) + '?' +
    'hymnal=' + hymnalName +
    '&title=' + hymnTitle +
    '&number=' + hymnNumber.toString() +
    '&psalm=' + psalmName +
    (hymn.issue ? '&issue=' + hymn.issue : '');

  return (url);
}