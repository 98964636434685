import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Message, SubscribeContextQueryString, TextInput} from './common';
import {Button} from './button';
import {Frame} from './frame';
import {navigateTo} from '../../util/use_location';
import {isValidEmail, withSearchParams} from '../../common/util';
import * as server_api from "../../common/server_api";
import {alert} from '../../util/confirm';
import {cacheGet, CacheKey, cacheSet} from '../../data/client_cache';
import {synchronizeChurchWithServer} from '../../data/use_church';
import {OnboardingPages} from '../../common/pages';
import {getHouseholdFromServer} from '../../data/use_household';
import {HouseholdStatus} from '../../common/model';

export const HouseholdFind = () => {
  const emailAddressInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    emailAddressInput.current?.focus();
  }, [emailAddressInput]);

  const onContinue = useCallback(async () => {
    const householdEmail = emailAddressInput.current?.value;
    if (!householdEmail || !isValidEmail(householdEmail)) {
      await alert({confirmation: <span>Please enter a valid email address</span>});
      return;
    }
    let householdToken = await server_api.getHouseholdToken({householdEmail});
    const household = householdToken && await getHouseholdFromServer(householdToken);
    if (!household) {
      navigateTo(withSearchParams(OnboardingPages.HouseholdNotFound));
      return;
    }
    cacheSet(CacheKey.Household, household);
    await synchronizeChurchWithServer({force: true});
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(SubscribeContextQueryString)) {
      if (household.status === HouseholdStatus.Subscribed) {
        navigateTo(OnboardingPages.SubscribeThankYou);
      } else {
        navigateTo(OnboardingPages.SubscribePayment);
      }
    } else {
      navigateTo(OnboardingPages.HouseholdWelcome);
    }
  }, [emailAddressInput]);

  const [isDisabled, setIsDisabled] = useState<boolean>(() => !cacheGet(CacheKey.OnboardingHouseholdEmail));
  const onEmailChange = useCallback(() => {
    setIsDisabled(!isValidEmail(emailAddressInput.current?.value));
    cacheSet(CacheKey.OnboardingHouseholdEmail, emailAddressInput.current?.value);
  }, []);

  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={18}>What email address was used for the household?</Message>
          <TextInput
            type='email'
            defaultValue={cacheGet(CacheKey.OnboardingHouseholdEmail)}
            onChange={onEmailChange}
            ref={emailAddressInput
          }/>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={isDisabled}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      containsInputElement={true}
    />
  );
}
