import React, {useCallback} from 'react';
import {Frame} from './frame';
import {musicLeaderMailToHRef, Message} from './common';
import {Button} from './button';
import {useHousehold} from '../../data/use_household';
import {navigateTo} from '../../util/use_location';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../common/pages';

export const ShareMusicLeader = () => {
  const {household} = useHousehold();

  const onNextPage = useCallback((page: OnboardingPages) => {
    navigateTo(household ? '/' : page);
  }, [household]);

  const onShare = useCallback(() => {
    onNextPage(OnboardingPages.ShareMusicLeaderInstructions);
  }, [onNextPage]);

  const onSkip = useCallback(() => {
    onNextPage(OnboardingPages.SurveyHouseholdSchedule);
  }, [onNextPage]);

  return (
    <Frame
      mainContent={(
        <Message $widthInChars={22}>
          No problem!<br/><br/>
          Get your church set up; Share this with anyone in the church to start the conversation.
        </Message>
      )}
      footerContent={(
        <>
          <Button>
            {/* iOS app requires that this be an anchor rather than a button to function */}
            <MusicLeaderLink href={musicLeaderMailToHRef} onClick={onShare}>CONTINUE</MusicLeaderLink>
          </Button>
          <Button onClick={onSkip}>MAYBE LATER</Button>
        </>
      )}
      onEnterKeypress={onShare}
    />
  );
};

const MusicLeaderLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
