import React, {useCallback} from 'react';
import {navigateTo} from '../../util/use_location';
import {ButtonContent, Message} from './common';
import {Frame} from './frame';
import {Button, LeftAlignedButton} from './button';
import {HouseholdSchedule} from '../../common/model';
import {useCache} from '../../data/use_cache';
import {CacheKey} from '../../data/client_cache';
import styled from 'styled-components/macro';
import {OnboardingPages} from '../../common/pages';

interface Option {
  prompt: string;
  householdSchedule: HouseholdSchedule;
}

export const SurveyHouseholdSchedule = () => {
  const onContinue = useCallback(() => {
    navigateTo(OnboardingPages.SurveyHouseholdNotifications);
  }, []);

  const options: Option[] = [
    {prompt: 'First thing in the morning', householdSchedule: HouseholdSchedule.Morning},
    {prompt: 'During our lunch time', householdSchedule: HouseholdSchedule.Lunch},
    {prompt: 'Around the dinner table', householdSchedule: HouseholdSchedule.Dinner},
    {prompt: 'Just before bedtime', householdSchedule: HouseholdSchedule.Bedtime},
  ];

  const [selectedOptions, setSelectedOptions] = useCache(CacheKey.OnboardingHouseholdSchedule);

  const onChooseOption = useCallback((householdSchedule: HouseholdSchedule) => {
    return () => {
      const isSelected = selectedOptions && selectedOptions.includes(householdSchedule);
      const newOptions = isSelected
        ? selectedOptions.filter(option => option !== householdSchedule)
        : [...(selectedOptions ?? []), householdSchedule];
      setSelectedOptions(newOptions);
    }
  }, [selectedOptions, setSelectedOptions]);

  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={16}>When will you sing as a household?</Message>
          <ButtonContent>
            {
              options.map((option) => (
                <LeftAlignedButton
                  key={option.householdSchedule}
                  onClick={onChooseOption(option.householdSchedule)}
                  notPrimary={true}
                  selected={selectedOptions?.includes(option.householdSchedule)}
                >
                  <OptionWrapper>
                    {option.prompt}
                  </OptionWrapper>
                </LeftAlignedButton>
              ))
            }
          </ButtonContent>
        </>
      )}
      footerContent={(
        <Button onClick={onContinue} disabled={!selectedOptions || selectedOptions.length === 0}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};

const OptionWrapper = styled.div`
  padding: 10px 25px;
`;
