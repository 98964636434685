import {useLocation} from '../util/use_location';
import * as server_api from '../common/server_api';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {DeprecatedDownloadPage, DeprecatedHouseholdBillingPage, isInsideMobileApp} from '../util/billing';
import {BackgroundGradient} from '../shared';
import {useHousehold} from '../data/use_household';
import {AppConfig} from '../common/model';
import {getOnboardingPath, OnboardingPages} from '../common/pages';
import {useAppConfig} from '../data/app_config';

export const AdminInvite = () => {
  const {location} = useLocation();
  const {household} = useHousehold();
  const householdToken = household?.token;
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState('');
  const {search} = location;
  const enableOnboarding = useAppConfig(AppConfig.EnableOnboarding);
  const urlParams = new URLSearchParams(window.location.search);
  const churchId = urlParams.get('church-id');
  const churchAdminToken = urlParams.get('church-admin-token');

  useEffect(() => {
    if (churchId && churchAdminToken && householdToken && !finished && !error) {
      (async () => {
        try {
          // skipping this step for now - will retroactively grant admin privileges in the future when Coda is nixed
          // await server_api.grantChurchAdmin({churchId: Number(churchId), churchAdminToken, userToken});
          // await server_api.setUserHousehold({householdToken});
          await server_api.setHouseholdChurch(householdToken, {id: Number(churchId)});
          const oneHundredYearsHence = new Date();
          oneHundredYearsHence.setFullYear(new Date().getFullYear() + 100);
          await server_api.setHouseholdSubscriptionExpirationTimestamp(householdToken, oneHundredYearsHence.getTime());
          setFinished(true);
        } catch (e: any) {
          setError(e.message);
        }
      })();
    }
  }, [churchId, churchAdminToken, householdToken, finished, error]);

  const onContinue = useCallback(() => {
    if (isInsideMobileApp()) {
      window.location.href = '/';
      return;
    }
    window.location.href = enableOnboarding ? getOnboardingPath(OnboardingPages.Download) : DeprecatedDownloadPage;
  }, [enableOnboarding]);

  if (!householdToken) {
    // TODO(hewitt): route through new onboarding household creation and back to this page
    window.location.href = DeprecatedHouseholdBillingPage + search;
    return null;
  }

  if (!churchId || !churchAdminToken) {
    return (
      <BackgroundGradient>
        <Message>
          An internal error has occurred. <a href="mailto:support@crescendosw.com?subject=Missing church tokens for admin invite" style={{color: "blue", textDecoration: "underline"}}>Click
          here</a> to reach out to support.
        </Message>
        <CloseButton onClick={onContinue}>Close</CloseButton>
      </BackgroundGradient>
    );
  }

  // useful for debugging
  // return (
  //   <div>
  //     <div>Household Token: {householdToken}</div>
  //     <div>User Token: {userToken}</div>
  //     <div>Church ID: {churchId}</div>
  //     <div>Church Admin Token: {churchAdminToken}</div>
  //   </div>
  // );

  if (error) {
    return (
      <BackgroundGradient>
        <Message>{error}</Message>
        <CloseButton onClick={onContinue}>Continue</CloseButton>
      </BackgroundGradient>
    );
  }

  if (!finished) {
    return <Message>Setting up church administrator account...</Message>
  }

  return (
    <BackgroundGradient>
      <Message>Your household has been granted full access to the hymnal library.</Message>
      <CloseButton onClick={onContinue}>Continue</CloseButton>
    </BackgroundGradient>
  );
}

const Message = styled.div`
  font-size: 2em;
  padding: 25% 5% 0;
`

const CloseButton = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.5em;
  margin: 10px;
  border: none;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
`
