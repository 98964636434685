import React, {useCallback} from 'react';
import {Message} from './common';
import {Frame} from './frame';
import {Button} from './button';

export const AllowNotifications = () => {
  const onContinue = useCallback(() => {
    // TODO(hewitt): navigate to next page
  }, []);
  return (
    <Frame
      mainContent={(
        <Message $widthInChars={16}>Allow notifications</Message>
      )}
      footerContent={(
        <Button onClick={onContinue}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
    />
  );
};
