import React, {useCallback, useEffect, useRef} from 'react';
import {navigateTo} from '../../util/use_location';
import {editAllHouseholdFields, Message, TextInput} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {CacheKey} from '../../data/client_cache';
import {alert} from '../../util/confirm';
import {useCache} from '../../data/use_cache';
import {OnboardingPages} from '../../common/pages';
import {withSearchParams} from '../../common/util';

export const HouseholdLastName = () => {
  const lastNameInput = useRef<HTMLInputElement>(null);
  const [householdEmail] = useCache(CacheKey.OnboardingHouseholdEmail);

  useEffect(() => {
    lastNameInput.current?.focus();
  }, [lastNameInput]);

  const [lastName, setLastName] = useCache(CacheKey.OnboardingLastName);
  const onLastNameChange = useCallback(() => {
    setLastName(lastNameInput.current?.value)
  }, [setLastName]);

  const onContinue = useCallback(async () => {
    if (!lastNameInput.current?.value) {
      await alert({confirmation: <span>'Please enter your last name'</span>});
      return;
    }
    navigateTo(withSearchParams(
      !householdEmail || editAllHouseholdFields()
        ? OnboardingPages.HouseholdEmail
        : OnboardingPages.HouseholdCreate
    ));
  }, [householdEmail]);

  return (
    <Frame
      mainContent={(
        <>
          <Message $widthInChars={18}>What is your last name?</Message>
          <TextInput
            type='text'
            enterKeyHint='next'
            defaultValue={lastName}
            onChange={onLastNameChange}
            ref={lastNameInput}
            autoCapitalize='words'
          />
        </>
      )}
      footerContent={(
        <Button type='submit' onClick={onContinue} disabled={!lastName}>CONTINUE</Button>
      )}
      onEnterKeypress={onContinue}
      containsInputElement={true}
    />
  );
}
