import "../settings_page.css"
import "./verse_count.css"
import {ReactComponent as CheckMarkIcon} from "../../assets/check-mark-icon.svg";
import {useCache} from "../../data/use_cache";
import {CacheKey} from "../../data/client_cache";
import {useCallback} from 'react';

type Props = {
  back: () => void;
}

export const VerseCount = (props: Props) => {

  const [verseCount, setVerseCount] = useCache(CacheKey.VerseCount);

  const onSetVerseCount = useCallback((num: number | undefined) => {
    setVerseCount(num);
    props.back();
  }, [setVerseCount, props]);



  return (
      <div className="settingsContent">
        <div className="settingsBox">
          <div className="settings verseCountSettings">
            <div key="1" className="setting verseCountSetting" onClick={() => onSetVerseCount(1)}>
                <div key="label" className="verses">
                  1
                </div>
                <div key="value" className="checkMarkCell"  hidden={verseCount !== 1}>
                  <CheckMarkIcon className="checkMarkIcon"/>
                </div>
            </div>
            <div key="2" className="setting verseCountSetting" onClick={() => onSetVerseCount(2)}>
              <div key="label" className="verses">
                2
              </div>
              <div key="value" className="checkMarkCell" hidden={verseCount !== 2}>
                <div className="checkMarkIcon">
                  <CheckMarkIcon />
                </div>
              </div>
            </div>
            <div key="all" className="setting verseCountSetting" onClick={() => onSetVerseCount(undefined)}>
              <div key="label" className="verses">
                All
              </div>
              <div key="value" className="checkMarkCell" hidden={verseCount === 1 || verseCount === 2}>
                <div className="checkMarkIcon">
                  <CheckMarkIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explanation">
          Verse Count determines how many times a song plays through,
          before stopping, repeating, or going to the next song.
          Setting the verse count to 'All' will make the song play through as
          many verses as are in a given song. If we do not have this information for
          a song, it will default to 4 verses.
        </div>
      </div>
  )
}